<template dark>
  <div class="renderPage">
    <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2">
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="cia_registrados" @click="limpiar()">
        Bancas Registradas
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="cia_nuevos" @click="limpiar()">
        Banca
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="cia_registrados">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-magnify" label="Búsqueda"
                single-line hide-details dense>
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="cia_nuevos">
        <v-form v-model="valid">
          <div>
            <v-expansion-panels multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>General</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.companyId" label="Cedula" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="6">
                        <v-text-field v-model="editedItem.nombre" label="Nombre Banca" :rules="nameRules" required
                          class="pa-0" :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.porcPaga" label="Porcentaje TICA" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.porcPagaExt" label="Porcentaje Extranjeras" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.minutosAntesCierre" label="Minutos Antes Cierre" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.montoPermitido" label="Monto Maximo por sorteo" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.porcPagaReventando" label="Porcentaje Reventado" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field v-model="editedItem.porcPagaReventandoX" label="Porcentaje Reventado X"
                          class="pa-0" :disabled="incluirValid"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>Sorteos</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col col="12" md="4" sm="4">
                        <v-select label="Sorteo" item-text="descripcion" item-value="id" v-model="sorteoDropDown"
                          :items="sorteos" class="pa-0" @change="changeSorteos" :disabled="incluirValid"></v-select>
                      </v-col>

                      <v-col cols="12" sm="2" md="2">
                        <v-text-field v-model="sorteoeditedItem.montoPermitido" label="Monto Maximo" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col align="center" cols="12" sm="6" md="3">
                        <v-btn color="primary" dark class="mb-2" @click="saveSorteos" :disabled="incluirValid">
                          Agregar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-data-table :headers="headersSorteos" :items="grupoSorteos" :search="search">
                    <template v-slot:top>
                      <v-toolbar height="30">
                        <!-- <v-spacer></v-spacer> -->
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="text-h5">Desea descartar el sorteo?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" class="mb-2" @click="closeDeleteSorteos">Cancelar</v-btn>
                              <v-btn color="primary" class="mb-2" @click="deleteItemConfirmSorteos">OK</v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.delete`]="{ item }">
                      <v-btn color="red" class="pa-0" @click="deleteItemSorteos(item)">
                        <v-icon color="white"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>Parámetros del sistema</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col col="12" md="6" sm="6">
                        <v-select label="Format Version" v-model="editedItem.formatVersion"
                          :items="['GenerarTiqueteReportFor80mm', 'GenerarTiqueteReportFor80mmV2', 'GenerarTiqueteReportFor80mmV3']"
                          class="pa-0" :disabled="incluirValid"></v-select>
                      </v-col>

                      <v-col cols="12" sm="2" md="2">
                        <v-text-field v-model="editedItem.diasLimiteCanje" label="Días Limite Canje" class="pa-0"
                          :disabled="incluirValid"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="8" md="8">
                        <v-textarea label="Pie del tiquete"
                          v-model="editedItem.footerText"
                          name="input-7-1" variant="filled" auto-grow></v-textarea>
                      </v-col>

                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </div>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()" dark>
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import Cabys from "./Cabys.vue";

export default {
  components: {
    Snackbar,
    //Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      existeSorteo: false,
      sorteoDropDown: "",
      formatVersion: "",
      footerText: "",
      diasLimiteCanje: 0,
      grupoSorteos: [],
      tiqueteConfigs: [],
      tiqueteFooters: [],
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      headers: [
        {
          text: "Cédula",
          align: "start",
          value: "companyId",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      headersSorteos: [
        {
          text: "Nombre",
          align: "start",
          value: "descripcion",
        },
        {
          text: "Monto Permitido",
          align: "start",
          value: "montoPermitido",
        },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      items: [],
      sorteos: [],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        companyId: "",
        porcPaga: 0,
        porcPagaExt: 0,
        minutosAntesCierre: 0,
        montoPermitido: 0,
        porcPagaReventando: 0,
        porcPagaReventandoX: 0,
        formatVersion: "",
        diasLimiteCanje: 0,
        footerText: "",
      },
      defaultItem: {
        nombre: "",
        companyId: "",
        porcPaga: 0,
        porcPagaExt: 0,
        minutosAntesCierre: 0,
        montoPermitido: 0,
        porcPagaReventando: 0,
        porcPagaReventandoX: 0,
        formatVersion: "",
        diasLimiteCanje: 0,
        footerText: "",
      },
      sorteoditedIndex: -1,
      sorteoeditedItem: {
        id: 0,
        descripcion: "",
        montoPermitido: 0,
      },
      sorteodefaultItem: {
        id: 0,
        descripcion: "",
        montoPermitido: 0,
      },
      tiqueteFootereditedItem: {
        value: 0,
        companyId: ""
      },
      tiqueteFooterdefaultItem: {
        value: 0,
        companyId: ""
      },

      tiqueteConfigeditedItem: {
        configKey: "",
        configValue: "",
        companyId: "",
      },
      tiqueteConfigdefaultItem: {
        configKey: "",
        configValue: "",
        companyId: "",
      },
      modeloCia: {
        cia: [],
        grupoSorteos: [],
        tiqueteConfig: [],
        tiqueteFooter: [],
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
      tempFile: "",
      tempFileImage: "",
      modelFile: null,
      modelFileImage: null,
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
    //this.getTiposIdentificacion();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Tiempos/getBancas", {
          crossdomain: true,
        })
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    limpiar() {
      this.snackValue = false;
    },

    getSorteos() {
      let me = this;
      axios
        .get("api/Tiempos/GetGrupoSorteos")
        .then(function (response) {
          me.sorteos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    closeDeleteSorteos() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.sorteoeditedItem = Object.assign({}, this.sorteodefaultItem);
        this.sorteoeditedIndex = -1;
      });
    },

    deleteItemSorteos(item) {
      this.sorteoeditedIndex = this.grupoSorteos.indexOf(item);
      this.sorteoeditedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirmSorteos() {
      this.grupoSorteos.splice(this.sorteoeditedIndex, 1);
      this.closeDeleteSorteos();
    },

    saveSorteos() {
      this.addNewSorteos();
    },

    changeSorteos() {
      this.getSorteo();
    },

    getSorteo() {
      let me = this;
      axios
        .get("api/Tiempos/GetGrupoSorteosById?id=" + this.sorteoDropDown, {
          crossdomain: true,
        })
        .then(function (response) {
          me.sorteoeditedItem = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getModeloCia() {
      let me = this;
      axios
        .get("api/Tiempos/GetModeloCia?companyId=" + this.editedItem.companyId, {
          crossdomain: true,
        })
        .then(function (response) {
          me.grupoSorteos = response.data.grupoSorteos;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addNewSorteos() {
      this.existeSorteo = false;
      this.grupoSorteos.find((object) => {
        if (object.id === this.sorteoDropDown) {
          this.existeSorteo = true;
        }
      });

      if (this.existeSorteo === false) {
        if (this.sorteoeditedItem.id != "") {
          if (this.sorteoeditedIndex > -1) {
            // this.snackText = "Existe:" + this.existeCia;
            // this.snackValue = true;
          } else {
            this.grupoSorteos.push(this.sorteoeditedItem);
            this.existeSorteo = false;
          }
          this.closeSorteos();
        }
      }
    },

    closeSorteos() {
      this.dialog = false;
      this.$nextTick(() => {
        this.sorteoeditedItem = Object.assign({}, this.sorteodefaultItem);
        this.sorteoeditedIndex = -1;
      });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getSorteos();
      this.getModeloCia();
      //this.changeProvincia();
      //this.changeCantones();
      //this.changeDistritos();
      //this.getActividades();
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    save() {
      let me = this;
      //this.editedItem.cia = this.strCia;
      this.modeloCia.cia = this.editedItem;
      this.modeloCia.grupoSorteos = this.grupoSorteos;

      if (this.editedIndex > -1) {
        axios
          .post("/api/Tiempos/UpdateModeloCia", this.modeloCia)
          .then(function () {
            me.snackText = "Registro modificado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      } else {
        axios
          .post("/api/Tiempos/InsertModeloCia", this.modeloCia)
          .then(function () {
            me.snackText = "Registro agregado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
      this.snackValue = false;
      //this.changeEsReceptor();
    },

    deleteItem() {
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el articulo?")) {
        axios
          .delete("api/Tiempos/deleteClientes?cia=" + this.editedItem.cedula)
          .then(function () {
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
        this.cerrar();
        this.tab2 = 0;
        this.esRec = false;
      }
    },

    closed() {
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
      });
    },

    onImageChange(ev) {
      this.tempFile = ev;
      this.editedItem.certificado = ev.name;
    },

    onChangeCiaImage(ev) {
      this.tempFileImage = ev;
      this.editedItem.imagePath = ev.name;
    },

    incluir() {
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.esRec = false;
        this.getSorteos();
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>