<template dark>
  <div class="renderPage">
    <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2">
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="cl_registrados" @click="limpiar()">
        Clientes Registrados
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="cl_nuevos" @click="limpiar()">
        Cliente
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="cl_registrados">
        <v-data-table :headers="headers" :items="items" :search="search" class="custom-table">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-magnify" label="Búsqueda"
                single-line hide-details dense>
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="cl_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="editedItem.celular" label="# Celular" required class="pa-0"
                  :disabled="incluirValid"></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field v-model="editedItem.nombre" label="Nombre" :rules="nameRules" required class="pa-0"
                  :disabled="incluirValid" @change="changeValueComponent"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn color="primary" :disabled="!eliminarValid" @click="deleteItem()">
              Eliminar
            </v-btn>
            <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()" dark>
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container> -->

          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn color="primary" :disabled="!incluirValid" @click="incluir()" density="compact">Incluir</v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn color="primary" :disabled="!guardarValid" @click="save()" density="comfortable">Guardar</v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()"  density="default">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import Cabys from "./Cabys.vue";

export default {
  components: {
    Snackbar,
    //Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      esRec: false,
      esExo: false,
      tabCliente: 0,
      modal: false,
      headers: [
        {
          text: "# Celular",
          align: "start",
          value: "celular",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        celular: "",
        nombre: "",
        saldo: 0,
      },
      defaultItem: {
        celular: "",
        nombre: "",
        saldo: 0,
      },
      cliente: {
        celular: "",
        nombre: "",
        saldo: 0,
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/KAAM/GetClientesAll", {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    limpiar() {
      this.snackValue = false;
    },

    getCliente() {
      let me = this;
      axios
        .get(
          "api/Clientes/getCliente?cia=" +
          this.strCia +
          "&cedula=" +
          this.editedItem.cedula
        )
        .then(function (response) {
          //me.codigoArticulo = response.data;
          me.cliente = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    save() {
      this.snackValue = false;
      let me = this;
      var correcto = true;
      var error = "";

      if (
        this.editedItem.nombre === "" 
      ) {
        correcto = false;
        error = "Por favor valide la información del cliente a registrar";
      }

      if (!correcto) {
        me.snackText = error;
        me.snackValue = true;
      } else {
        if (this.editedIndex > -1) {
          axios
            .post("api/KAAM/InsertCliente", this.editedItem)
            .then(function () {
              me.snackText = "Registro modificado satisfactoriamente";
              me.snackValue = true;
              me.getData();
            })
            .catch(function (error) {
              console.log(error);
              // me.saved(false);
            });
        } else {
          axios
            .post("api/KAAM/InsertCliente", this.editedItem)
            .then(function (response) {
              me.snackText = "Registro agregado satisfactoriamente";
              me.editedItem.ceular = response.data;
              me.snackValue = true;
              me.getData();
            })
            .catch(function (error) {
              console.log(error);
              // me.saved(false);
            });
        }
        this.cancelarValid = true;
        this.eliminarValid = true;
        this.guardarValid = true;
        this.incluirValid = false;
        this.snackValue = false;
      }
    },

    changeValueComponent() {
      this.snackValue = false;
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.snackValue = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
        //this.$refs.form.resetValidation();
      });
    },

    incluir() {
      this.snackValue = false;
      //this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        //this.$refs.form.resetValidation();
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

/* .v-data-table .v-data-table tr th {
  font-size: 24px !important;
} */

/* .custom-table td {
   color: green; 
  font-weight: bold;
} */

.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>