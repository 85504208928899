<template>
    <div class="renderPage">
        <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
            <v-spacer />
            <v-toolbar-title class="text-capitalize">
                Historico Premios
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" to="/home">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <v-col cols="12" sm="12" md="12">
                    <v-expansion-panels multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Filtros</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-form ref="form" v-model="valid">
                                            <v-row>
                                                <v-col cols="12" sm="10" md="10">
                                                    <v-dialog ref="dialog" v-model="dateModal"
                                                        :return-value.sync="dates" persistent width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="dates" label="Periodo"
                                                                prepend-icon="mdi-calendar" readonly required
                                                                :rules="dateRules" v-bind="attrs" v-on="on" class="pa-0"
                                                                hide-details></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="dates" range>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="dateModal = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn text color="primary"
                                                                @click="$refs.dialog.save(dates)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </v-col>
                                                <v-col cols="12" sm="2" md="2">
                                                    <v-btn color="primary" class="pa-0" @click="getReport()"
                                                        :disabled="!valid">
                                                        Generar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-card>
                        <v-data-table v-if="validInfo" :headers="headers" :items="items">
                            <template v-slot:top> </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    mdi-flash-red-eye
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogInformacionAdicional" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Tiquetes Ganadores</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-card class="mx-auto my-1" v-if="validInfo">
                                    <!-- <v-card-title>Ventas por sorteo</v-card-title> -->
                                    <div class="px-4">
                                        <v-chip-group>
                                            <v-chip>{{ nombreSorteo }}</v-chip>
                                            <v-chip>{{ fecha }}</v-chip>
                                            <v-chip>{{ numeroGanador }}</v-chip>
                                        </v-chip-group>
                                    </div>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-data-table :headers="headers2" :items="items2">
                                </v-data-table>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mb-2" @click="cancelarInformacionAdicional">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    components: {},
    data() {
        return {
            valid: false,
            validDecargar: false,
            validInfo: false,
            dateModal: false,
            dialogInformacionAdicional: false,
            dates: [],
            items: [],
            items2: [],
            nombreSorteo: '?',
            fecha: '?',
            numeroGanador: '?',
            dateRules: [
                (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
            ],
            headers: [
                {
                    text: "Sorteo",
                    align: "start",
                    value: "sorteoNombre",
                },
                {
                    text: "Fecha",
                    align: "start",
                    value: "fechaString",
                },
                {
                    text: "Venta Total",
                    align: "start",
                    value: "montoVendido",
                },
                {
                    text: "# Ganador",
                    align: "start",
                    value: "numeroGanador",
                },
                {
                    text: "Total Premios",
                    align: "start",
                    value: "montoPerdido",
                },
                { text: "Ver Premios", value: "actions", width: 50, sortable: false },
            ],
            headers2: [
                {
                    text: "# Tiquete",
                    align: "start",
                    value: "idTiquete",
                },
                {
                    text: "Jugador",
                    align: "start",
                    value: "nombreCliente",
                },
                {
                    text: "Monto",
                    align: "start",
                    value: "monto",
                },
                {
                    text: "Premio",
                    align: "start",
                    value: "premio",
                },
                {
                    text: "Canjeado",
                    align: "start",
                    value: "canjeadoString",
                },
                {
                    text: "Fecha de canje",
                    align: "start",
                    value: "fechaCanjeString",
                },
            ],
            reporte: {
                fechaInicio: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
                fechaFin: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
            },
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
    },

    created() {
    },

    methods: {
        getReport() {
            this.getReportePremios();
            //this.getRecibidos();
        },

        editItem(item) {
            this.snackValue = false;
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.getReportePremiosByDate();
            this.mostrarInformacionAdicional();
        },

        mostrarInformacionAdicional() {
            this.dialogInformacionAdicional = true;
        },

        cancelarInformacionAdicional() {
            this.dialogInformacionAdicional = false;
        },

        getReportePremiosByDate() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetAllPremiosByDate?idSorteo=" +
                    this.editedItem.idSorteo +
                    "&fecha=" +
                    this.editedItem.fecha.toString() +
                    "&companyId=" +
                    this.strCia,
                )
                .then(function (response) {
                    me.items2 = response.data;
                    me.nombreSorteo = "Sorteo: " + response.data[0].sorteoNombre;
                    me.fecha = "Fecha: " + me.editedItem.fechaString;
                    me.numeroGanador = "# Ganador: " + response.data[0].numero;
                    me.validInfo = true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getReportePremios() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetReporteHistoricoPremios?fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString() +
                    "&companyId=" +
                    this.strCia,
                )
                .then(function (response) {
                    me.items = response.data;
                    me.validInfo = true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        closed() {
            this.customerModal = false;
        },
    },
};
</script>

<style>
.actionsCol .v-btn {
    margin-right: 10px;
}
</style>