<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Reporte Comisión
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-col cols="12" sm="3" md="12">
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>Filtros</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12" sm="4" md="3">
                          <v-dialog
                            ref="dialog"
                            v-model="dateModal"
                            :return-value.sync="dates"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dates"
                                label="Periodo"
                                prepend-icon="mdi-calendar"
                                readonly
                                required
                                :rules="dateRules"
                                v-bind="attrs"
                                v-on="on"
                                class="pa-0"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="dateModal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(dates)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            v-model="reporte.cedula"
                            label="Cédula Usuario"
                            class="pa-0"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-btn
                            color="primary"
                            class="pa-0"
                            @click="getReport()"
                            :disabled="!valid"
                          >
                            Generar Reporte
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-card>
            <v-data-table :headers="headers" :items="items"> </v-data-table>
          </v-card>
        </v-col>

        <!-- <v-data-table :headers="headers" :items="items"> </v-data-table> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      valid: false,
      validDecargar: false,
      dateModal: false,
      dates: [],
      items: [],
      tab2: 0,
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      headers: [
        // {
        //   text: "Usuario",
        //   align: "start",
        //   value: "idUsuario",
        // },
        {
          text: "Sorteo",
          align: "start",
          value: "sorteo",
        },
        {
          text: "Importe",
          align: "start",
          value: "importe",
        },
        // {
        //   text: "Fecha",
        //   align: "start",
        //   value: "fechaString",
        // },
        {
          text: "Comisión",
          align: "start",
          value: "comisionGanada",
        },
        {
          text: "Premio",
          align: "start",
          value: "premio",
        },
        {
          text: "Balance",
          align: "start",
          value: "balance",
        },
        {
          text: "% Pagado",
          align: "start",
          value: "porcPagado",
        },
      ],
      reporte: {
        cedula: "",
        fechaInicio: Date,
        fechaFin: Date,
      },
    };
  },

  created() {
    this.reporte.cedula = this.user.cedula;
  },

  methods: {
    getReport() {
      this.getReporteVentas();
      //this.getRecibidos();
    },

    getReporteVentas() {
      let me = this;
      axios
        .get(
          "api/Tiempos/GetReporteComision?idUsuario=" +
            this.reporte.cedula +
            "&fechaInicio=" +
            this.dates[0].toString() +
            "&fechaFin=" +
            this.dates[1].toString()
        )
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    closed() {
      this.customerModal = false;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
.actionsCol .v-btn {
  margin-right: 10px;
}
</style>