<template>
  <v-app>
    <v-app-bar app elevation="2">
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-spacer></v-spacer>

      <v-col cols="6">
        <v-select menu-props="auto" label="Compañia" hide-details prepend-icon="mdi-city" single-line
          item-text="ciaNombre" item-value="ciaId" v-model="cia" :items="companies" @change="changeComapny"></v-select>
      </v-col>

      <v-chip outlined>
        <v-icon left> mdi-account-circle-outline </v-icon>
        {{ user.nombre }}
      </v-chip>

      <v-btn to="/" icon elevation="" color="" class="ml-1 ml-md-2 ml-lg-2">
        <v-icon> mdi-logout </v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app fixed v-model="drawer">
      <v-list-item>
        <v-list-item-icon class="align-self-center" contain>
          <img height="35" src="../assets/icono.png" alt="John" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="display-1" /> Eduva</v-list-item-content>
      </v-list-item>

      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item link :to="inicio.path" :replace="replace">
            <v-list-item-icon>
              <v-icon>{{ inicio.icono }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ inicio.titulo }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-for="item in items" :key="item.menu">
            <v-list-item link :to="item.path" :replace="replace" v-if="!item.menuOpciones">
              <v-list-item-icon>
                <v-icon>{{ item.icono }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.titulo }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group sub-group no-action :prepend-icon="item.icono" class="subItem" v-if="item.menuOpciones">
              <template v-slot:activator>
                <v-list-item-title> {{ item.titulo }} </v-list-item-title>
              </template>

              <v-list-item v-for="subItem in item.menuOpciones" :key="subItem.titulo" link :to="subItem.path"
                :replace="replace">
                <v-list-item-icon>
                  <v-icon>{{ subItem.icono }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subItem.titulo }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>

        <v-spacer></v-spacer>

        <v-list-item justify-end>
          <v-switch v-model="$vuetify.theme.dark" inset label="Modo oscuro"></v-switch>
        </v-list-item>

        <v-card-text>
          <span @click="openDialog" style="cursor: pointer;">{{ new Date().getFullYear() }}</span> —
          <strong @click="openDialog" style="cursor: pointer;">Eduva Solutions</strong>
          <!-- Botón de Facebook -->
          <v-btn icon color="blue darken-3" class="ml-3" :href="facebookLink" target="_blank" alt="Facebook">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
        </v-card-text>

      </v-list>
    </v-navigation-drawer>

    <v-main class="ma-2 ma-md-4 ma-lg-4">
      <transition name="slide" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="620">
        <v-card>
          <v-card-title class="text-h6">
            Si presiona aceptar perderá todos los datos que no hayan sido
            guardados, ¿está seguro que quiere cambiar de puesto?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelar()">
              Cancelar
            </v-btn>
            <v-btn color="red darken-1" text @click="aceptar()">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog -->
      <v-dialog v-model="dialogInfo" max-width="500">
        <v-card>
          <v-card-title class="red--text">Aviso Legal</v-card-title>
          <v-card-text>
            Eduva Solutions es una empresa de desarrollo de herramientas informaticas, y soluciones a medida para
            nuestros
            clientes alrededor del mundo.
          </v-card-text>
          <v-card-text>
            Eduva Solutions no se hace responsable del uso que nuestros clientes y sus usuarios finales le den a las
            herramientas y/o software que hemos desarrollado bajo sus requerimientos.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialogInfo = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "../components/shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      drawer: true,
      replace: true,
      selectedItem: "",
      cia: "",
      ciaOld: "",
      items: [],
      companies: [],
      dialog: false,
      dialogInfo: false,
      facebookLink: "https://www.facebook.com/Eduva-Solutions-109625341739954",
      inicio: {
        titulo: "Inicio",
        icono: "mdi-view-dashboard",
        //path: "/tiempos",
        path: "/resultados",
      },
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.getModeloMenu();
    this.getCompanies();
    this.cia = this.user.ciaPorDefecto;
    this.ciaOld = this.user.ciaPorDefecto;
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },

  methods: {
    ...mapMutations(["setStrCia"]),

    changeComapny() {
      this.dialog = true;
    },

    aceptar() {
      this.setStrCia(this.cia);
      this.ciaOld = this.cia;
      this.dialog = false;
      this.$router.replace({ name: "Resultados" });
    },

    openDialog() {
      this.dialogInfo = true; // Abre el diálogo
    },

    closeDialog() {
      this.dialogInfo = false; // cierra el diálogo
    },

    cancelar() {
      this.cia = this.ciaOld;
      this.dialog = false;
    },

    getCompanies() {
      let me = this;
      me.setStrCia(this.user.ciaPorDefecto);
      axios
        .get("api/Usuarios/getCiasPorUsuario?usuarioId=" + this.user.id)
        .then(function (response) {
          me.companies = response.data;
          me.cia = this.user.ciaPorDefecto;
          me.ciaOld = this.user.ciaPorDefecto;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getModeloMenu() {
      let me = this;
      me.setStrCia(this.user.ciaPorDefecto);
      axios
        .get("api/Usuarios/getModeloMenu?usuarioId=" + this.user.id)
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    preventNav(event) {
      event.preventDefault();
    },
  },
};
</script>

<style>
.main {
  max-height: 100%;
  max-width: 100%;
}
</style>