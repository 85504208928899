<template>
    <div class="renderPage">
        <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
            <v-spacer />
            <v-toolbar-title class="text-capitalize">
                Canjear
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" to="/home">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card>
            <v-data-table :headers="headers" :items="items">
                <!-- <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" hide-details @click="cerrarSorteoAutomatico()">
                            <v-icon left> mdi-replay </v-icon>
                            Actualizar
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </template> -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="mr-2" @click="editItem(item)">
                        mdi-coin
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Snackbar from "./shared/Snackbar.vue";

export default {
    components: {
        Snackbar,
    },
    data() {
        return {
            snackText: "",
            snackValue: false,
            fecha: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
            items: [],
            editedItem: [],
            headers: [
                {
                    text: "Sorteo",
                    align: "start",
                    value: "sorteoNombre",
                },
                {
                    text: "# Tiquete",
                    align: "start",
                    value: "idTiquete",
                },
                {
                    text: "Fecha",
                    align: "start",
                    value: "fechaString",
                },
                {
                    text: "Cliente",
                    align: "start",
                    value: "nombreCliente",
                },
                {
                    text: "Total",
                    align: "start",
                    value: "total",
                },
                {
                    text: "Detalle",
                    align: "start",
                    value: "detalle",
                },
                { text: "Canjear", value: "actions", width: 50, sortable: false },
            ],
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
    },

    created() {
        this.getPremiosAll();
    },

    methods: {
        getPremiosAll() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetPremiosAll?fecha=" +
                    this.fecha.toString() +
                    "&companyId=" +
                    this.strCia,
                )
                .then(function (response) {
                    me.items = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        cerrarSorteoAutomatico() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/CerrarSorteosAutomatico"
                )
                .then(function () {
                    me.getPremiosAll();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        closed() {
            this.customerModal = false;
        },

        editItem(item) {
            this.snackValue = false;
            this.editedItem = Object.assign({}, item);
            let me = this;
            if (confirm("¿Está seguro de que desea canjear el premio?")) {
                axios
                    .post("api/Tiempos/CanjearPremio", this.editedItem)
                    .then(function (response) {
                        if (response.data) {
                            console.log(response.data);
                            me.snackText = "Se ha canjeado el premio correctamente";
                            me.snackValue = true;
                            me.getPremiosAll();
                        } else {
                            me.snackText = "Ha ocurrido un error al canjear el premio, por favor contacte a soporte";
                            me.snackValue = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        // me.saved(false);
                    });
            }
        },

        // editItem(item) {
        //     this.editedItem = Object.assign({}, item);
        //     let me = this;
        //     axios
        //         .post("api/Tiempos/CanjearPremio", this.editedItem)
        //         .then(function (response) {
        //             if (response.data) {
        //                 me.snackText =
        //                     "Se ha canjeado el premio correctamente";
        //                 me.snackValue = true;
        //                 me.getPremiosAll();
        //             } else {
        //                 me.snackText =
        //                     "Ha ocurrido un error al canjear el premio, por favor contacte a soporte";
        //                 me.snackValue = true;
        //             }
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
    },
};
</script>

<style>
.actionsCol .v-btn {
    margin-right: 10px;
}
</style>