<template>
    <div class="renderPage">
        <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
            <v-spacer />
            <v-toolbar-title class="text-capitalize">
                Exportar Listas
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" to="/home">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <v-col cols="12" sm="12" md="12">
                    <v-expansion-panels multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Filtros</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-form ref="form" v-model="valid">
                                            <v-row>
                                                <!-- <v-col cols="12" sm="7" md="7">
                                                    <v-dialog ref="dialog" v-model="dateModal"
                                                        :return-value.sync="dates" persistent width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="dates" label="Periodo"
                                                                prepend-icon="mdi-calendar" readonly required
                                                                :rules="dateRules" v-bind="attrs" v-on="on" class="pa-0"
                                                                hide-details></v-text-field>
                                                        </template>
<v-date-picker v-model="dates" range>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="dateModal = false">
        Cancel
    </v-btn>
    <v-btn text color="primary" @click="$refs.dialog.save(dates)">
        OK
    </v-btn>
</v-date-picker>
</v-dialog>
</v-col> -->

                                                <v-col col="12" md="6" sm="6">
                                                    <v-select :label="Sorteos" item-text="descripcion" item-value="id"
                                                        v-model="idSorteo" :items="sorteos" @change="onSelectedCategory"
                                                        outlined dense hide-details></v-select>
                                                </v-col>

                                                <v-col col="12" md="3" sm="3">
                                                    <v-select label="Usuarios" item-text="nombre" item-value="cedula"
                                                        v-model="reporte.cedula" :items="usuarios"
                                                        :disabled="!validUsuarios" class="pa-0"></v-select>
                                                </v-col>

                                                <v-col cols="12" sm="2" md="2">
                                                    <v-btn color="primary" class="pa-0" @click="downloadAndShareCsv()"
                                                        :disabled="!valid">
                                                        Exportar
                                                    </v-btn>
                                                </v-col>

                                                <v-dialog transition="dialog-bottom-transition" v-model="dialogReport">
                                                    <div v-if="showNewData">
                                                        <VerNumerosVendidos2 v-bind:allwedOnly="true"
                                                            v-bind:numerosInfo="numerosInfo" @closed="closed" />
                                                    </div>
                                                </v-dialog>

                                            </v-row>
                                        </v-form>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VerNumerosVendidos2 from "./VerNumerosVendidos2.vue";

export default {
    components: {
        VerNumerosVendidos2,
    },
    data() {
        return {
            dialogReport: false,
            showNewData: false,
            valid: false,
            validUsuarios: false,
            validDecargar: false,
            dateModal: false,
            idSorteo: "",
            dates: [],
            items: [],
            items2: [],
            usuarios: [],
            sorteos: [],
            modeloReporteVentas: {
                sorteos: [],
                detalles: [],
            },
            numerosInfo: {
                idUsuario: "",
                idSorteo: "",
                fechaInicio: new Date(),
                fechaFin: new Date(),
            },
            tab2: 0,
            dateRules: [
                (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
            ],
            tiqueteInfo: {
                idTiquete: "",
                tipoTiquete: "",
            },
            reporte: {
                cedula: "",
                fechaInicio: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
                fechaFin: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
            },
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
    },

    created() {
        this.reporte.cedula = this.user.cedula;
        this.getUsuariosPorCia();
        this.getSorteos();
    },

    methods: {
        getReport() {
            this.getReporteVentas();
            //this.getRecibidos();
        },

        editItem(item) {
            this.snackValue = false;
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.verNumerosVendidos();
        },

        getSorteos() {
            let me = this;
            axios
                .get("api/Tiempos/GetSorteosParaExportar?companyId=" + this.strCia)
                .then(function (response) {
                    me.sorteos = response.data;
                    me.idSorteo = me.sorteos[0]["id"];
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        verNumerosVendidos() {
            this.numerosInfo.idSorteo = this.editedItem.idSorteo;
            this.numerosInfo.fechaInicio = this.editedItem.fecha;
            this.numerosInfo.fechaFin = this.editedItem.fecha;
            this.numerosInfo.idUsuario = this.reporte.cedula;
            //this.numerosInfo.modeloReporteVentas = this.modeloReporteVentas;
            this.dialogReport = true;
            this.showNewData = true;
        },

        async downloadAndShareCsv() {
            try {
                // Hacer la solicitud a la API para obtener el archivo CSV
                const response = await axios.get("api/Tiempos/GetReporteExcel?idUsuario=" +
                    this.reporte.cedula +
                    "&fechaInicio=" +
                    new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .substr(0, 10) +
                    "&fechaFin=" +
                    new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .substr(0, 10) +
                    "&companyId=" +
                    this.strCia +
                    "&idSorteo=" +
                    this.idSorteo, {
                    responseType: 'blob'  // Indicar que esperamos un archivo binario (Blob)
                });

                // Llamar a la función para compartir el archivo si es posible
                this.shareFile(response.data);

            } catch (error) {
                console.error("Error al obtener el archivo CSV:", error);
            }
        },

        // Función para compartir el archivo usando la Web Share API
        async shareFile(blob) {
            // Verificar si la Web Share API está disponible en el navegador
            if (navigator.share) {
                try {
                    const indexOfSlash = this.sorteos.find(item => item.id === this.idSorteo).descripcion.indexOf('/');
                    const namex = this.sorteos.find(item => item.id === this.idSorteo).descripcion.replace(/:/g, '_').substring(0, indexOfSlash);
                    const fileName = `sorteo_${namex}.csv`; // nombre dinámico
                    console.log(fileName);
                    const file = new File([blob], fileName, { type: 'text/csv' });

                    // Llamar a la API Web Share para compartir el archivo
                    await navigator.share({
                        title: 'Archivo CSV',
                        text: 'Aquí tienes el archivo CSV generado',
                        files: [file],  // El archivo CSV que vamos a compartir
                    });

                    console.log("Archivo compartido exitosamente");

                } catch (error) {
                    console.error("Error al compartir el archivo:", error);
                    alert("Hubo un error al intentar compartir el archivo.");
                }
            } else {
                // Si la Web Share API no está disponible, hacer fallback a la descarga
                console.log("La API Web Share no es compatible con este navegador.");
                this.downloadFile(blob);
            }
        },

        // Función para descargar el archivo si Web Share no está disponible
        downloadFile(blob) {
            // Crear un enlace de descarga
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = 'archivo.csv';  // Nombre del archivo
            link.click();  // Simular clic para descargar el archivo
            window.URL.revokeObjectURL(url);  // Liberar el objeto URL después de la descarga
        },

        getUsuariosPorCia() {
            let me = this;
            axios
                .get(
                    "api/Usuarios/getUsuariosPorCia?companyId=" +
                    this.strCia,
                )
                .then(function (response) {
                    me.usuarios = response.data;
                    console.log(me.user.cedula);
                    const myUser = me.usuarios.find(user => user.cedula === me.user.cedula);
                    console.log(myUser.tipo);
                    if (myUser.tipo === true) {
                        me.reporte.cedula = "0";
                        me.validUsuarios = true;
                    } else {
                        me.reporte.cedula = myUser.cedula;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async GetReporteExcel() {
            try {
                // Hacer una solicitud GET a la API para obtener el archivo Excel
                const response = await axios.get("api/Tiempos/GetReporteExcel?idUsuario=" +
                    this.reporte.cedula +
                    "&fechaInicio=" +
                    new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .substr(0, 10) +
                    "&fechaFin=" +
                    new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .substr(0, 10) +
                    "&companyId=" +
                    this.strCia +
                    "&idSorteo=" +
                    this.idSorteo, {
                    //responseType: 'arraybuffer', // Especificamos que la respuesta será un archivo binario
                    responseType: 'blob', // Especificamos que la respuesta será un archivo binario
                });

                // Crear un Blob a partir del archivo recibido
                //const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Crear un enlace temporal para la descarga
                //const link = document.createElement('a');
                //link.href = URL.createObjectURL(blob);
                //link.download = 'sorteo.xlsx'; // Nombre del archivo que se descargará

                // Simular un clic en el enlace para iniciar la descarga
                //link.click();
                // Llamamos a la función para abrir el Excel en una nueva ventana
                this.openFileInNewWindow(response.data);

            } catch (error) {
                console.error('Error al descargar el archivo:', error);
            }
        },

        shareExcel() {
            axios.get("api/Tiempos/GetReporteExcel?idUsuario=" +
                this.reporte.cedula +
                "&fechaInicio=" +
                new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 10) +
                "&fechaFin=" +
                new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 10) +
                "&companyId=" +
                this.strCia +
                "&idSorteo=" +
                this.idSorteo, { responseType: 'blob' })
                .then(response => {
                    const blob = response.data;
                    const file = new File([blob], 'sorteo.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Check if the Web Share API is supported
                    if (navigator.share) {
                        navigator.share({
                            title: 'Archivo Excel',
                            files: [file], // Share the Excel file
                        })
                            .then(() => {
                                console.log('Archivo compartido');
                            })
                            .catch((error) => {
                                console.error('Error al compartir:', error);
                                alert("Error al intentar compartir el archivo. Puede que tu navegador no sea compatible.");
                            });
                    } else {
                        // If Web Share is not supported, show a download link instead
                        console.log("Web Share API no es compatible con este navegador. Se descargará el archivo.");

                        // Create a link for downloading the file
                        const link = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        link.href = url;
                        link.download = 'archivo.xlsx'; // The name of the file
                        link.click();
                        window.URL.revokeObjectURL(url); // Free the object URL after download
                    }
                })
                .catch(error => {
                    console.error('Error al generar el archivo Excel:', error);
                });
        },

        // Función para abrir el archivo Excel en una nueva ventana
        openFileInNewWindow(blob) {
            // Crea un URL temporal para el Blob
            const url = window.URL.createObjectURL(blob);

            // Abre el archivo Excel en una nueva ventana o pestaña
            const newWindow = window.open(url, '_blank');

            if (!newWindow) {
                alert('No se pudo abrir la nueva ventana. Asegúrese de que los pop-ups estén habilitados.');
            }

            // Opcional: Revocar la URL temporal después de un tiempo
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 10000); // 10 segundos
        },

        getReporteVentas() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetReporteVentas?idUsuario=" +
                    this.reporte.cedula +
                    "&fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString() +
                    "&companyId=" +
                    this.strCia,
                )
                .then(function (response) {
                    me.modeloReporteVentas = response.data;
                    me.items = me.modeloReporteVentas.sorteos;
                    me.items2 = me.modeloReporteVentas.detalles;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getRecibidos() {
            let me = this;
            axios
                .get(
                    "api/Reportes/getFacturasRecibidas?cia=" +
                    this.strCia +
                    "&fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString()
                )
                .then(function (response) {
                    me.items2 = response.data;
                    me.validDecargar = true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        closed() {
            this.customerModal = false;
            this.showNewData = false;
            this.dialogReport = false;
        },
    },
};
</script>

<style>
.actionsCol .v-btn {
    margin-right: 10px;
}
</style>