<template dark>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        BUSCAR TIQUETE
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-row>
        <!-- <v-col col="12" md="3" sm="3">
          <v-select label="Sorteo" :items="items" @change="onSelected" outlined dense hide-details></v-select>
        </v-col> -->

        <v-col cols="12" sm="6" md="6">
          <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="dates" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dates" label="Periodo" prepend-icon="mdi-calendar" readonly required
                :rules="dateRules" v-bind="attrs" v-on="on" class="pa-0" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="dates" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <!-- <v-col cols="12" sm="3" md="3">
          <v-text-field v-model="editedItem.idTiquete" label="# Tiquete" @change="changeValueComponent"
            @input="changeValueComponent" outlined dense hide-details></v-text-field>
        </v-col> -->

        <v-col col="12" md="3" sm="3">
          <v-select label="Usuarios" item-text="nombre" item-value="cedula" v-model="reporte.cedula" :items="usuarios"
            :disabled="!validUsuarios" class="pa-0"></v-select>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn color="primary" hide-details @click="getReporteVentas()">
            <v-icon left> mdi-checkbox-marked </v-icon>
            Buscar
          </v-btn>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-data-table :headers="headers" :items="items2" :search="search">
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-magnify" label="Búsqueda"
                  single-line hide-details dense>
                </v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn color="primary" class="pa-0" @click="editItem(item)">
                <v-icon color="white"> mdi-flash-red-eye </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn color="red" class="pa-0" @click="deleteItem(item)">
                <v-icon color="white"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>

        </v-col>

        <v-dialog persistent transition="dialog-bottom-transition" v-model="dialogReport" max-width="400">
          <div v-if="showNewData">
            <Tiquete v-bind:allwedOnly="true" v-bind:tiqueteInfo="tiqueteInfo" @closed="closed" />
          </div>
        </v-dialog>
      </v-row>
    </v-container>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Tiquete from "./Tiquete.vue";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
    Tiquete,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      snackText: "",
      search: "",
      snackValue: false,
      validUsuarios: false,
      dialogReport: false,
      showNewData: false,
      editedIndex: -1,
      dates: [],
      datesFormat: [],
      usuarios: [],
      dateModal: false,
      items: ['Normal', 'Reventados'],
      items2: [],
      tiqueteReportInfo: [],
      headers: [
        {
          text: "Sorteo",
          align: "start",
          value: "sorteo",
        },
        {
          text: "Usuario",
          align: "start",
          value: "nombre",
        },
        {
          text: "# Tiquete",
          align: "start",
          value: "idTiquete",
        },
        {
          text: "Cliente",
          align: "start",
          value: "cliente",
        },
        {
          text: "Números",
          align: "start",
          value: "numeros",
        },
        {
          text: "Total",
          align: "start",
          value: "total",
        },
        {
          text: "Anulado",
          align: "start",
          value: "anulado",
        },
        { text: "Ver", value: "actions", width: 50, sortable: false },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      modeloReporteVentas: {
        sorteos: [],
        detalles: [],
      },
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      tiqueteInfo: {
        idTiquete: "",
        tipoTiquete: "",
        reimpresion: "Si",
      },
      defaultItem: {
        idTiquete: 0,
        tipoTiquete: "",
      },
      editedItem: {
        idTiquete: 0,
        tipoTiquete: "",
      },
      nameRules: [(v) => !!v || "Campo requerido"],
      reporte: {
        cedula: "",
        fechaInicio: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
        fechaFin: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
      },
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.reporte.cedula = this.user.cedula;
    this.getUsuariosPorCia();
    this.editedItem = Object.assign({}, this.editedItem);
    this.setDates();
    //this.editedItem.sorteo = "Normal";
    //this.getReporteVentas();
  },

  methods: {
    getTiqueteReport() {
      this.tiqueteInfo.idTiquete = this.editedItem.idTiquete;
      this.tiqueteInfo.tipoTiquete = this.editedItem.tipoTiquete;
      this.tiqueteInfo.reimpresion = "Si";
      this.getTiqueteInfo();
      // this.dialogReport = true;
      // this.showNewData = true;
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getTiqueteReport();
    },

    setDates() {
      var date = new Date();
      //var dateInicio = Date();
      //var dateFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dates[0] = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.dates[1] = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      this.datesFormat[0] = dt + "-" + month + "-" + year;
      this.datesFormat[1] = dt + "-" + month + "-" + year;
    },

    setDatesFormat() {
      //eslint-disable-next-line
      var date = new Date(
        this.dates[0].toString().replace(/-/g, "/").replace(/T.+/, "")
      );

      //eslint-disable-next-line
      var date2 = new Date(
        this.dates[1].toString().replace(/-/g, "/").replace(/T.+/, "")
      );

      var year = date2.getFullYear();
      var month = date2.getMonth() + 1;
      var dt = date2.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      var yearInicio = date.getFullYear();
      var monthInicio = date.getMonth() + 1;
      var dtInicio = date.getDate();

      if (dtInicio < 10) {
        dtInicio = "0" + dtInicio;
      }
      if (monthInicio < 10) {
        monthInicio = "0" + monthInicio;
      }

      // this.snackText = "llega antes de sumar" + this.dates[0] + "-" + this.dates[1] + "luego de sumar: " + dtInicio + '-' + monthInicio + '-' + yearInicio + "-" + dt + '-' + month + '-' + year;
      // this.snackValue = true;

      this.datesFormat[0] = dtInicio + "-" + monthInicio + "-" + yearInicio;
      this.datesFormat[1] = dt + "-" + month + "-" + year;
    },

    getUsuariosPorCia() {
      let me = this;
      axios
        .get(
          "api/Usuarios/getUsuariosPorCia?companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.usuarios = response.data;
          console.log(me.user.cedula);
          const myUser = me.usuarios.find(user => user.cedula === me.user.cedula);
          console.log(myUser.tipo);
          if (myUser.tipo === true) {
            me.reporte.cedula = "0";
            me.validUsuarios = true;
          } else {
            me.reporte.cedula = myUser.cedula;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    deleteItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items2.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el tiquete?")) {
        axios
          .get("api/Tiempos/EliminarTiquete" + this.editedItem.tipoTiquete + "?id=" + this.editedItem.idTiquete +
            "&companyId=" +
            this.strCia,)
          .then(function (response) {
            if (response.data) {
              me.getReporteVentas();
              me.snackText = "Se ha eliminado correctamente";
              me.snackValue = true;
            } else {
              me.snackText = "El número de tiquete se encuentra eliminado o no se puede eliminar";
              me.snackValue = true;
            }
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
    },

    getTiqueteInfo() {
      this.snackValue = false;
      let me = this;
      axios
        .get(
          "api/Tiempos/GetTiquete" + this.editedItem.tipoTiquete + "Report?idTiquete=" + this.editedItem.idTiquete +
          "&companyId=" +
          this.strCia +
          "&reimpresion=" +
          this.tiqueteInfo.reimpresion
        )
        .then(function (response) {
          me.tiqueteReportInfo = response.data;

          if (!me.tiqueteReportInfo.cancelado) {
            me.dialogReport = true;
            me.showNewData = true;
          } else {
            me.snackText = "El número de tiquete que busca ya fue eliminado";
            me.snackValue = true;
          }
          //console.log(me.tiqueteReport.bancaNombre);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getReporteVentas() {
      let me = this;
      axios
        .get(
          "api/Tiempos/GetReporteVentasConAnulados?idUsuario=" +
          this.reporte.cedula +
          "&fechaInicio=" +
          this.dates[0].toString() +
          "&fechaFin=" +
          this.dates[1].toString() +
          "&companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.modeloReporteVentas = response.data;
          me.items2 = me.modeloReporteVentas.detalles;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    onSelected(name) {
      if (name === "Normal") {
        this.tiqueteInfo.tipoTiquete = "";
      }

      if (name === "Reventados") {
        this.tiqueteInfo.tipoTiquete = "Reventado";
      }

      if (name === "Monazos") {
        this.tiqueteInfo.tipoTiquete = "Monazo";
      }
    },

    changeValueComponent() {
      this.snackValue = false;
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialogReport = false;
    },

    save() {
      //this.snackValue = false;
      this.editedItem.idUsuario = this.user.cedula;
      let me = this;

      if (this.numeros.length < 1) {
        me.snackText = "Por favor seleccione los números a vender";
        me.snackValue = true;
      } else {
        axios
          .post("api/Tiempos/InsertTiquetes", this.editedItem)
          .then(function (response) {
            me.tiqueteInfo.idTiquete = response.data;
            me.snackText =
              "Se ha registrado el tiquete #" +
              response.data +
              " correctamente";
            me.snackValue = true;
            me.editedItem.numeros = "";
            me.editedItem.monto = 0;
            me.editedItem.total = 0;
            me.numeros = [];
            me.restringidos = "";
            me.getTiqueteReport();
            me.getSorteos();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.text-input-blue .v-text-field__slot input {
  color: red !important;
}
</style>