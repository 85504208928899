<template>
    <div class="renderPage">
        <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
            <v-spacer />
            <v-toolbar-title class="text-capitalize">
                Control Ventas
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" to="/home">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <v-col cols="12" sm="12" md="12">
                    <v-expansion-panels multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Filtros</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <v-form ref="form" v-model="valid">
                                            <v-row>
                                                <v-col cols="12" sm="10" md="10">
                                                    <v-dialog ref="dialog" v-model="dateModal"
                                                        :return-value.sync="dates" persistent width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="dates" label="Periodo"
                                                                prepend-icon="mdi-calendar" readonly required
                                                                :rules="dateRules" v-bind="attrs" v-on="on" class="pa-0"
                                                                hide-details></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="dates" range>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="dateModal = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn text color="primary"
                                                                @click="$refs.dialog.save(dates)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </v-col>
                                                
                                                <v-col cols="12" sm="2" md="2">
                                                    <v-btn color="primary" class="pa-0" @click="getReport()"
                                                        :disabled="!valid">
                                                        Generar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-card class="mx-auto my-1" v-if="validInfo">
                    <v-card-title>Ventas por sorteo</v-card-title>
                    <div class="px-4">
                        <v-chip-group>
                            <v-chip>{{ venta }}</v-chip>
                            <v-chip>{{ premios }}</v-chip>
                            <v-chip>{{ comisionAdmin }}</v-chip>
                            <v-chip>{{ totalAdmin }}</v-chip>
                        </v-chip-group>
                    </div>
                </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-card>
                        <v-data-table v-if="validInfo" :headers="headers" :items="items"> </v-data-table>
                    </v-card>
                </v-col>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    components: {},
    data() {
        return {
            valid: false,
            validDecargar: false,
            validInfo: false,
            dateModal: false,
            dates: [],
            items: [],
            venta: 'g',
            premios: 'g',
            comisionAdmin: 'g',
            totalAdmin: 'g',
            dateRules: [
                (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
            ],
            headers: [
                {
                    text: "Sorteo",
                    align: "start",
                    value: "sorteo",
                },
                {
                    text: "Fecha",
                    align: "start",
                    value: "fechaSorteo",
                },
                {
                    text: "Venta",
                    align: "start",
                    value: "venta",
                },
                {
                    text: "Premios",
                    align: "start",
                    value: "premios",
                },
                {
                    text: "Comisión",
                    align: "start",
                    value: "comisionAdmin",
                },
                {
                    text: "Total Admin",
                    align: "start",
                    value: "totalAdmin",
                },
            ],
            reporte: {
                fechaInicio: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
                fechaFin: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
            },
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
    },

    created() {
    },

    methods: {
        getReport() {
            this.getReporteVentas();
            //this.getRecibidos();
        },

        getReporteVentas() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetReporteControlVentas?fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString() +
                    "&companyId=" +
                    this.strCia,
                )
                .then(function (response) {
                    me.items = response.data;
                    me.venta = "Ventas: " + response.data[0].venta;
                    me.premios = "Premios: " + response.data[0].premios;
                    me.comisionAdmin = "Comisión: " + response.data[0].comisionAdmin;
                    me.totalAdmin = "Total Admin: " + response.data[0].totalAdmin;
                    me.validInfo = true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        closed() {
            this.customerModal = false;
        },
    },
};
</script>

<style>
.actionsCol .v-btn {
    margin-right: 10px;
}
</style>