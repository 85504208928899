<template>
  <v-container fluid>

    <v-card max-width="380">

      <div v-html="tiqueteReport.adicional" ref="element" id="ticket"></div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" hide-details @click="share">
          <v-icon left> mdi-share-variant </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" hide-details @click="print">
          <v-icon left> mdi-printer </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" hide-details @click="printTicket">
          <v-icon left> mdi-bluetooth </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" hide-details @click="selected">
          <v-icon left> mdi-replay </v-icon>
        </v-btn>
        <!-- <v-btn color="error" hide-details @click="close()">
          <v-icon left> mdi-close </v-icon>
        </v-btn> -->
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close()">
          <v-icon left> mdi-close </v-icon>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>


    <!-- <v-col cols="12" sm="3" md="3">
      <v-btn color="primary" hide-details @click="share">
        <v-icon left> mdi-share-variant </v-icon>
        Compartir
      </v-btn>
    </v-col> -->
  </v-container>
</template>
<script>
import axios from "axios";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";

export default {
  props: {
    tiqueteInfo: {
      idTiquete: "",
      tipoTiquete: "",
      reimpresion: "",
    },
    allwedOnly: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    itemsPerPage: 4,
    tiqueteReport: [],
    info: "",
    printerCharacteristic: null,
    isConnected: false,
    tiqueteReport2: {
      bancaNombre: 'My Bank',
      nombre: 'John Doe',
      sorteo: '12345',
      tiquete: 'A12345',
      fechaVenta: '2024-08-10',
      items: [
        { numero: '001', monto: '10.00' },
        { numero: '002', monto: '15.00' },
      ],
      total: '25.00',
      porcPaga: '1',
    }
  }),
  computed: {
    ...mapGetters(["strCia"]),
  },

  created() {
    this.getTiqueteReport();
    this.$vuetify.theme.dark = false;
    this.generarTiqueteReport();
  },
  methods: {

    async connectToPrinter() {
      try {
        const device = await navigator.bluetooth.requestDevice({
          filters: [{ services: ['printer_service'] }] // Replace with your printer's service UUID
        });

        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('printer_service'); // Replace with your printer's service UUID
        const characteristic = await service.getCharacteristic('printer_characteristic'); // Replace with your printer's characteristic UUID

        this.printerCharacteristic = characteristic;
        this.isConnected = true;
        console.log('Connected to printer');
      } catch (error) {
        console.error('Error connecting to Bluetooth device:', error);
        this.isConnected = false;
      }
    },

    async printTicket2() {
      if (!this.printerCharacteristic) {
        console.log('No printer connected');
        return;
      }

      const ticketData = this.generateTicketData();
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(ticketData);

      try {
        await this.printerCharacteristic.writeValue(encodedData);
        console.log('Print job sent');
      } catch (error) {
        console.error('Error sending print job:', error);
      }
    },

    generateTicketData() {
      // Generate the ticket data in the required format
      return `
        ${this.padCenter(this.tiqueteReport.bancaNombre, 32)}
        ${this.padCenter(this.tiqueteReport.nombre, 32)}
        ${this.padCenter(this.tiqueteReport.sorteo, 32)}
        ------------------------------
        ${this.padCenter(this.tiqueteReport.tiquete, 32)}
        ${this.padCenter(this.tiqueteReport.fechaVenta, 32)}
        ------------------------------
        Número    Monto
        ${this.tiqueteReport.items.map(item => `${this.padRight(item.numero, 10)} ${this.padLeft(item.monto, 10)}`).join('\n')}
        ------------------------------
        Total: ${this.padLeft(this.tiqueteReport.total, 32)}
        ------------------------------
        REVISE SIEMPRE SU TIQUETE
        Se paga ${this.tiqueteReport.porcPaga} veces
        GRACIAS POR JUGAR!
      `;
    },

    padRight(text, length) {
      return (text + ' '.repeat(length)).substring(0, length);
    },

    padLeft(text, length) {
      return (' '.repeat(length) + text).substring(text.length, length);
    },

    padCenter(text, length) {
      const spaces = length - text.length;
      const padLeft = Math.floor(spaces / 2);
      const padRight = spaces - padLeft;
      return ' '.repeat(padLeft) + text + ' '.repeat(padRight);
    },

    getTiqueteReport() {
      this.snackValue = false;
      let me = this;
      axios
        .get(
          "api/Tiempos/GetTiquete" + this.tiqueteInfo.tipoTiquete + "Report?idTiquete=" + this.tiqueteInfo.idTiquete +
          "&companyId=" +
          this.strCia +
          "&reimpresion=" +
          this.tiqueteInfo.reimpresion
        )
        .then(function (response) {
          me.tiqueteReport = response.data;
          //console.log(me.tiqueteReport.bancaNombre);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    generarTiqueteReport() {
      //const fecha = new Date().toLocaleDateString();
      // Construyendo la cadena HTML de la tabla con los valores dinámicos
      //console.log(this.tiqueteReport.bancaNombre);
      let tablaHTML = '<table class="item">';
      tablaHTML += '<thead><tr><th>Número</th><th>Monto</th></tr></thead>';
      tablaHTML += '<tbody>';

      // Agregando dinámicamente filas a la tabla para cada producto
      this.tiqueteReport.forEach(num => {
        tablaHTML += `<tr>
                  <td>${num.numero}</td>
                  <td>${num.monto}</td>
                </tr>`;
      });

      tablaHTML += '</tbody></table>';

      this.info = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Tiquete</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 13px;
    }
    .ticket {
      width: 250px;
      margin: 0 auto;
      /* border: 1px solid #000; */
      padding: 0px;
    }
    .header {
      font-weight: bold;
      text-align: center; 
      font-size: 13px;
    }
    .item {
      text-align: center; 
      margin-bottom: 1px;
      font-size: 12px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .total {
      font-weight: bold;
      text-align: center; 
      font-size: 13px;
    }
    .footer {
      margin-top: 5px;
      text-align: center; 
    }
  </style>
</head>
<body>

<div class="ticket">
    <div class="header">
        <span>${this.tiqueteReport.bancaNombre}</span>
    </div>
    <div class="header">
        <span>${this.tiqueteReport.nombre}</span>
    </div>
    <div class="header">
        <span>${this.tiqueteReport.sorteo}</span>
    </div>
    <hr>
  
  <div class="item">
    <span>${this.tiqueteReport.tiquete}</span>
  </div>
  <div class="item">
    <span>${this.tiqueteReport.fechaVenta}</span>
  </div>

  <div class="line"></div>

  <hr>

  ${tablaHTML} <!-- Aquí se inserta la tabla dinámica -->

<hr>

  <div class="total">
    <span>Total:</span> <span>${this.tiqueteReport.total}</span>
  </div>
  <hr>
  <div class="footer">
    <span>REVISE SIEMPRE SU TIQUETE</span>
    <span>Se paga</span><span>${this.tiqueteReport.porcPaga}</span><span> veces</span>
    <span>GRACIAS POR JUGAR!</span>
  </div>
</div>

</body>
</html>
`;
    },

    selected() {
      this.dialog = false;
      this.$emit("clicked");
    },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },

    share() {
      // iife here
      (async () => {
        if (!("share" in navigator)) {
          console.log("Sharing not supported");
          return;
        }
        // `element` is the HTML element you want to share.
        // `backgroundColor` is the desired background color.
        const canvas = await html2canvas(this.$refs.element);

        canvas.toBlob(async (blob) => {
          // Even if you want to share just one file you need to
          // send them as an array of files.
          const files = [new File([blob], "image.png", { type: blob.type })];
          const shareData = {
            text: "",
            title: "",
            files,
          };
          if (navigator.canShare(shareData)) {
            try {
              await navigator.share(shareData);
            } catch (err) {
              if (err.name !== "AbortError") {
                console.error(err.name, err.message);
              }
            }
          } else {
            console.warn("Sharing not supported", shareData);
          }
        });
      })();
    },

    printTicket() {
      // Use HTML2Canvas to generate an image of the ticket
      html2canvas(this.$refs.element).then(function (canvas) {
        const imageData = canvas.toDataURL('image/png');
        // Create a new window for printing
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<img src="' + imageData + '">');
        // Print the ticket
        printWindow.print();
      });
    },

    printContent: function () {
      // var self = this;
      // Capture the target element
      html2canvas(this.$refs.element).then(function (canvas) {
        // Create an image from the captured canvas
        var imgData = canvas.toDataURL('image/png');
        // Create a new window for printing
        var printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        //printWindow.document.write('<style>@page { size: 100mm 150mm; margin: 0; }</style>');
        //printWindow.document.write('<style>@page { size: 80mm auto; margin: 0; }</style>');
        printWindow.document.write('<style>@page {height:279mm; width:80mm; }</style>');
        printWindow.document.write('<img src="' + imgData + '" style="width: 100%; height: 100%;"/>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        // Wait for image to load before printing
        printWindow.onload = function () {
          printWindow.focus(); // Focus on new window
          printWindow.print(); // Print the window
          printWindow.close(); // Close the window after printing
        };
      });
    },

    print: function () {
      const element = window.document.getElementById("ticket");//const ticketElem = this.$refs.element;
      var printPreview = window.open('', 'print_preview');
      var printDocument = printPreview.document;
      printDocument.open();
      printDocument.write(`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Tiquete</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 13px;
    }
    .ticket {
      width: 250px;
      margin: 0 auto;
      /* border: 1px solid #000; */
      padding: 0px;
    }
    .item {
      text-align: center; 
      margin-bottom: 1px;
      font-size: 13px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .footer {
      margin-top: 5px;
      text-align: center; 
    }
  </style>
</head>
<body>
<div class="ticket">
    <div class="item">
        <span>${element.innerHTML}</span>
    </div>
</body>
</html>
`);

      // printDocument.write(`
      //   <html>
      //     <head>
      //       <title></title>
      //     </head>
      //     <body>
      //       ${element.innerHTML}
      //     </body>
      //   </html>
      // `);
      printPreview.print();
      printPreview.close();

    },
  },
};
</script>

<style>
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body {
  font-family: Arial, sans-serif;
  font-size: 13px;
}

.ticket {
  width: 250px;
  margin: 0 auto;
  /* border: 1px solid #000; */
  padding: 0px;
}

.tickets-control {

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}

.tickets-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tickets-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.ticket-wrapper {
  width: 340px;
  padding: 5px;
}

.v-card-actions {
  justify-content: center;
  /* Center-align buttons horizontally */
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>