<template>
    <div class="renderPage">
        <v-row>
            <v-col cols="12" sm="4" md="4" v-for="(item, index) in lotteryList" :key="index">
                <v-card class="mx-auto my-1" >
                    <v-card-title>{{ item.lotteryName }}</v-card-title>
                    <div class="px-4">
                        <v-chip-group >
                            <v-chip color="primary">{{ item.listResults[0].number }}</v-chip>
                            <v-chip v-if="item.listResults.length > 1 && item.listResults[1].number === 'BB' ">{{ item.listResults[1].number }}</v-chip>
                            <v-chip color="red" variant="flat" v-if="item.listResults.length > 1 && item.listResults[1].number === 'BR' ">{{ item.listResults[1].number }}</v-chip>                        
                        </v-chip-group>
                    </div>
                    <v-card-text>
                        <div class="my-1 text-subtitle-2 font-weight-bold text-decoration-underline">
                            {{ item.date + " " + item.time }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<!-- <template>
    <div class="renderPage">
        <v-row>
            <v-col cols="12" sm="4" md="4" v-for="(item, index) in lotteryList" :key="index">
                <v-card variant="outlined">
                    <v-card-title>{{ item.lotteryName }}</v-card-title>
                    <div class="px-4">
                        <v-chip-group v-model="selection" v-for="(result, index) in item.listResults" :key="index">
                            <v-chip>{{ result.number }}</v-chip>
                        </v-chip-group>
                    </div>
                    <div class="px-4">
                        <v-chip>{{ item.listResults[0].number }}</v-chip>
                    </div>
                    <v-card-text>{{ item.date + " " + item.time }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template> -->

<script>
import axios from "axios";

export default {
    components: {},
    props: {
    },
    data() {
        return {
            icons: ["mdi-facebook", "mdi-whatsapp", "mdi-youtube"],
            items: [],
            snackText: "",
            snackValue: false,
            lotteryList: [],
        };
    },

    watch: {},

    created() {
        this.getData();
    },

    methods: {
        getData() {
            let me = this;
            axios
                .get("api/Tiempos/GetLotteryResultList")
                .then(function (response) {
                    me.lotteryList = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        reserve() {
            this.loading = true

            setTimeout(() => (this.loading = false), 2000)
        },
    },
};
</script>

<style>
.img {
    width: 100vw !important;
    height: 95vh !important;
}
</style>