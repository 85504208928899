<template>
    <v-container fluid>
        <v-data-iterator :items="items" :items-per-page.sync="itemsPerPage" hide-default-footer>
            <template v-slot:header>
                <v-toolbar class="mb-2" color="primary" dark flat>
                    <v-toolbar-title>Números vendidos</v-toolbar-title>
                    <v-divider></v-divider>
                    <v-btn large depressed color="red" @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                                {{ sorteoNombre }}
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="3" md="3">
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                                Número | Monto
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-list dense v-for="item in props.items.filter(info => info.numero < 25)" :key="item.name">
                                <v-list-item>
                                    <v-list-item-content>{{ item.numero }}</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        {{ item.monto }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="3" md="3">
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                                Número | Monto
                            </v-card-title>
                            <v-list dense
                                v-for="item in props.items.filter(info => info.numero > 24 && info.numero < 50)"
                                :key="item.name">
                                <v-list-item>
                                    <v-list-item-content>{{ item.numero }}</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        {{ item.monto }}
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="3" md="3">
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                                Número | Monto
                            </v-card-title>
                            <v-list dense
                                v-for="item in props.items.filter(info => info.numero > 49 && info.numero < 75)"
                                :key="item.name">
                                <v-list-item>
                                    <v-list-item-content>{{ item.numero }}</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        {{ item.monto }}
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="3" md="3">
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                                Número | Monto
                            </v-card-title>
                            <v-list dense
                                v-for="item in props.items.filter(info => info.numero > 74 && info.numero < 100)"
                                :key="item.name">
                                <v-list-item>
                                    <v-list-item-content>{{ item.numero }}</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        {{ item.monto }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
    props: {
        numerosInfo: {
            idUsuario: "",
            idSorteo: "",
            fechaInicio: new Date(),
            fechaFin: new Date(),
        },
        allwedOnly: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    data() {
        return {
            search: "",
            itemsPerPage: 100,
            items: [],
            sorteoNombre: "",
            modeloReporteVentas: {
                sorteos: [],
                detalles: [],
            },
        };
    },

    computed: {
        ...mapGetters(["strCia"]),
    },

    created() {
        this.getNumerosVendidos();
    },

    methods: {

        getNumerosVendidos() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetNumerosVendidos?idUsuario=" +
                    this.numerosInfo.idUsuario +
                    "&fechaInicio=" +
                    this.numerosInfo.fechaInicio.toString() +
                    "&fechaFin=" +
                    this.numerosInfo.fechaFin.toString() +
                    "&companyId=" +
                    this.strCia +
                    "&idSorteo=" +
                    this.numerosInfo.idSorteo,
                )
                .then(function (response) {
                    me.sorteoNombre = response.data.sorteoNombre;
                    me.items = response.data.detalles;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        selected(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.$emit("clicked", this.editedItem);
        },

        close() {
            this.dialog = false;
            this.$emit("closed");
        },
    },
};
</script>