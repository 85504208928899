<template dark>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        ELIMINAR TIQUETE
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col col="12" md="3" sm="3">
          <v-select label="Sorteo" 
          :items="items" @change="onSelected" outlined dense hide-details></v-select>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="editedItem.idTiquete"
            label="# Tiquete"
            @change="changeValueComponent"
            @input="changeValueComponent"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn color="primary" hide-details @click="deleteItem()">
            <v-icon left> mdi-checkbox-marked </v-icon>
            Eliminar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      snackText: "",
      snackValue: false,
      dialogReport: false,
      showNewData: false,
      editedIndex: -1,
      items: ['Normal', 'Reventados'],
      tiqueteInfo: {
        idTiquete: "",
        tipoTiquete: "",
      },
      defaultItem: {
        idTiquete: 0,
        tipoTiquete: "",
      },
      editedItem: {
        idTiquete: 0,
        tipoTiquete: "",
      },
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.editedItem = Object.assign({}, this.editedItem);
  },

  methods: {
    changeValueComponent() {
      this.snackValue = false;
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialogReport = false;
    },

    onSelected(name) {
      if (name === "Normal") {
        this.tiqueteInfo.tipoTiquete = "";
      }

      if (name === "Reventados") {
        this.tiqueteInfo.tipoTiquete = "Reventado";
      }

      if (name === "Monazos") {
        this.tiqueteInfo.tipoTiquete = "Monazo";
      }
    },

    deleteItem() {
      this.snackValue = false;
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el tiquete?")) {
        axios
          .get("api/Tiempos/EliminarTiquete"+this.tiqueteInfo.tipoTiquete+ "?id=" + this.editedItem.idTiquete+
            "&companyId=" +
            this.strCia,)
          .then(function (response) {
            if (response.data) {
            me.snackText = "Se ha eliminado correctamente";
            me.snackValue = true;
            }else{
            me.snackText = "El número de tiquete no existe o ya se encuentra eliminado";
            me.snackValue = true;
            }
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.text-input-blue .v-text-field__slot input {
  color: red !important;
}
</style>