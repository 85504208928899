<template dark>
    <div class="renderPage">
        <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
            <v-spacer />
            <v-toolbar-title class="text-capitalize"> Configuración Sorteos </v-toolbar-title>
            <v-spacer />
        </v-toolbar>

        <v-container>
            <v-row>
                <v-expansion-panels multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Límite general</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container>
                                <v-row>
                                    <v-col col="12" md="4" sm="4">
                                        <v-select label="Grupo Sorteos" item-text="descripcion" item-value="id"
                                            v-model="sorteoDropDown" :items="sorteos" class="pa-0"
                                            @change="changeSorteos"></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-text-field v-model="sorteoeditedItem.montoPermitido" label="Monto Maximo"
                                            class="pa-0"></v-text-field>
                                    </v-col>

                                    <v-col align="center" cols="12" sm="6" md="3">
                                        <v-btn color="primary" dark class="mb-2" @click="saveSorteos">
                                            Agregar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-data-table :headers="headersSorteos" :items="grupoSorteos" :search="search">
                                <template v-slot:top>
                                    <v-toolbar height="30">
                                        <v-dialog v-model="dialogArticulos" max-width="700px">
                                            <v-card>
                                                <v-card-title>
                                                    <span class="text-h5">Editar Sorteo</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" sm="5" md="3">
                                                                <v-text-field v-model="sorteoeditedItem.id" outlined
                                                                    dense label="Código Sorteo" readonly></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="4">
                                                                <v-text-field v-model="sorteoeditedItem.descripcion"
                                                                    label="Descripción" outlined dense
                                                                    readonly></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3">
                                                                <v-text-field v-model="sorteoeditedItem.montoPermitido"
                                                                    type="number" label="Monto Permitido" step="any"
                                                                    min="0" outlined dense></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="primary" class="mb-2" @click="closeDeleteSorteos">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn color="primary" class="mb-2" @click="saveDetalleArticulo">
                                                        Actualizar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                            <v-card>
                                                <v-card-title class="text-h5">Desea descartar el
                                                    sorteo?</v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="primary" class="mb-2"
                                                        @click="closeDeleteSorteos">Cancelar</v-btn>
                                                    <v-btn color="primary" class="mb-2"
                                                        @click="deleteItemConfirmSorteos">OK</v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-btn color="primary" class="pa-0" @click="detalleEditItem(item)">
                                        <v-icon color="white"> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:[`item.delete`]="{ item }">
                                    <v-btn color="red" class="pa-0" @click="deleteItemSorteos(item)">
                                        <v-icon color="white"> mdi-delete </v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Limite por número</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container>
                                <v-row>
                                    <v-col col="12" md="4" sm="4">
                                        <v-select label="Sorteo" item-text="descripcion" item-value="id"
                                            v-model="sorteoLimiteDropDown" :items="sorteosLimite" class="pa-0"
                                            @change="changeSorteosLimite"></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-text-field v-model="sorteoLimiteeditedItem.numeros" label="Número"
                                            class="pa-0"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-text-field v-model="sorteoLimiteeditedItem.montoPermitido" label="Monto Maximo"
                                            class="pa-0"></v-text-field>
                                    </v-col>

                                    <v-col align="center" cols="12" sm="6" md="3">
                                        <v-btn color="primary" dark class="mb-2" @click="saveSorteosLimite">
                                            Agregar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-data-table :headers="headersSorteosLimite" :items="grupoSorteosLimite" :search="search">
                                <template v-slot:top>
                                    <v-toolbar height="30">
                                        <!-- <v-spacer></v-spacer> -->
                                        <v-dialog v-model="dialogDeleteLimite" max-width="500px">
                                            <v-card>
                                                <v-card-title class="text-h5">Desea descartar el limite?</v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="primary" class="mb-2"
                                                        @click="closeDeleteSorteosLimite">Cancelar</v-btn>
                                                    <v-btn color="primary" class="mb-2"
                                                        @click="deleteItemConfirmSorteosLimite">OK</v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.delete`]="{ item }">
                                    <v-btn color="red" class="pa-0" @click="deleteItemSorteosLimite(item)">
                                        <v-icon color="white"> mdi-delete </v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-container>
        <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import Cabys from "./Cabys.vue";

export default {
    components: {
        Snackbar,
        //Cabys,
    },
    dark: true,
    data() {
        return {
            dialog: false,
            dialogArticulos: false,
            dialogDeleteLimite: false,
            valid: false,
            incluirValid: true,
            cancelarValid: false,
            eliminarValid: false,
            guardarValid: false,
            existeSorteo: false,
            existeSorteoLimite: false,
            sorteoDropDown: "",
            sorteoLimiteDropDown: "",
            formatVersion: "",
            footerText: "",
            diasLimiteCanje: 0,
            grupoSorteos: [],
            grupoSorteosLimite: [],
            tiqueteConfigs: [],
            tiqueteFooters: [],
            search: "",
            snackText: "",
            snackValue: false,
            tab2: "",
            headers: [
                {
                    text: "Cédula",
                    align: "start",
                    value: "companyId",
                },
                {
                    text: "Nombre",
                    align: "start",
                    value: "nombre",
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
            ],
            headersSorteos: [
                {
                    text: "Nombre",
                    align: "start",
                    value: "descripcion",
                },
                {
                    text: "Monto Permitido",
                    align: "start",
                    value: "montoPermitido",
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
                { text: "Eliminar", value: "delete", width: 70, sortable: false },
            ],
            headersSorteosLimite: [
                // {
                //     text: "Nombre",
                //     align: "start",
                //     value: "descripcion",
                // },
                {
                    text: "Número",
                    align: "start",
                    value: "numeros",
                },
                {
                    text: "Monto Permitido",
                    align: "start",
                    value: "montoPermitido",
                },
                { text: "Eliminar", value: "delete", width: 70, sortable: false },
            ],
            items: [],
            sorteos: [],
            sorteosLimite: [],
            editedIndex: -1,
            editedItem: {
                nombre: "",
                companyId: "",
                porcPaga: 0,
                porcPagaExt: 0,
                minutosAntesCierre: 0,
                montoPermitido: 0,
                porcPagaReventando: 0,
                porcPagaReventandoX: 0,
                formatVersion: "",
                diasLimiteCanje: 0,
                footerText: "",
            },
            defaultItem: {
                nombre: "",
                companyId: "",
                porcPaga: 0,
                porcPagaExt: 0,
                minutosAntesCierre: 0,
                montoPermitido: 0,
                porcPagaReventando: 0,
                porcPagaReventandoX: 0,
                formatVersion: "",
                diasLimiteCanje: 0,
                footerText: "",
            },
            sorteoditedIndex: -1,
            sorteoeditedItem: {
                id: 0,
                descripcion: "",
                montoPermitido: 0,
            },
            sorteodefaultItem: {
                id: 0,
                descripcion: "",
                montoPermitido: 0,
            },

            sorteoLimiteeditedIndex: -1,
            sorteoLimiteeditedItem: {
                companyId: "",
                montoPermitido: 0,
                numeros: "",
                idSorteo: 0,
            },
            sorteoLimitedefaultItem: {
                companyId: "",
                montoPermitido: 0,
                numeros: "",
                idSorteo: 0,
            },

            tiqueteFootereditedItem: {
                value: 0,
                companyId: ""
            },
            tiqueteFooterdefaultItem: {
                value: 0,
                companyId: ""
            },

            tiqueteConfigeditedItem: {
                configKey: "",
                configValue: "",
                companyId: "",
            },
            tiqueteConfigdefaultItem: {
                configKey: "",
                configValue: "",
                companyId: "",
            },
            modeloCia: {
                cia: [],
                grupoSorteos: [],
                tiqueteConfig: [],
                tiqueteFooter: [],
            },
            modeloSorteos: {
                companyId: "",
                grupoSorteos: [],
            },
            showNewData: false,
            nameRules: [(v) => !!v || "Campo requerido"],
            tempFile: "",
            tempFileImage: "",
            modelFile: null,
            modelFileImage: null,
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
    },

    watch: {
        dialog(val) {
            val || this.close();
        },

        search() {
            this.snackValue = false;
        },
    },

    created() {
        this.getSorteos();
        this.getModeloCia();
        this.getSorteosAll();
        //this.getTiposIdentificacion();
    },

    methods: {
        getData() {
            let me = this;
            axios
                .get("api/Tiempos/getBancas", {
                    crossdomain: true,
                })
                .then(function (response) {
                    me.items = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        limpiar() {
            this.snackValue = false;
        },

        getSorteos() {
            this.snackValue = false;

            let me = this;
            axios
                .get("api/Tiempos/GetGrupoSorteos")
                .then(function (response) {
                    me.sorteos = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getSorteosAll() {
            this.snackValue = false;
            let me = this;
            axios
                .get("api/Tiempos/GetSorteosAll?companyId=" + this.strCia)
                .then(function (response) {
                    me.sorteosLimite = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        saveDetalleArticulo() {
            this.snackValue = false;
            if (this.sorteoeditedIndex > -1) {
                Object.assign(
                    this.grupoSorteos[this.sorteoeditedIndex],
                    this.sorteoeditedItem
                );
            } else {
                this.grupoSorteos.push(this.sorteoeditedItem);
            }

            let me = this;
            //this.editedItem.cia = this.strCia;
            this.modeloSorteos.companyId = this.strCia;
            this.modeloSorteos.grupoSorteos = this.grupoSorteos;

            axios
                .post("/api/Tiempos/UpdateSorteosCia", this.modeloSorteos)
                .then(function () {
                    me.snackText = "Registro modificado satisfactoriamente";
                    me.snackValue = true;
                    me.getSorteosAll();
                    //me.getData();
                })
                .catch(function (error) {
                    console.log(error);
                    // me.saved(false);
                });

            this.closeSorteos();
        },

        detalleEditItem(item) {
            this.snackValue = false;
            this.sorteoeditedIndex = this.grupoSorteos.indexOf(item);
            this.sorteoeditedItem = Object.assign({}, item);
            this.dialogArticulos = true;
        },

        closeDeleteSorteos() {
            this.snackValue = false;
            this.dialogDelete = false;
            this.dialogArticulos = false;
            this.$nextTick(() => {
                this.sorteoeditedItem = Object.assign({}, this.sorteodefaultItem);
                this.sorteoeditedIndex = -1;
            });
        },

        closeDeleteSorteosLimite() {
            this.snackValue = false;
            this.dialogDeleteLimite = false;
            this.$nextTick(() => {
                this.sorteoLimiteeditedItem = Object.assign({}, this.sorteoLimitedefaultItem);
                this.sorteoLimiteeditedIndex = -1;
            });
        },

        deleteItemSorteos(item) {
            this.snackValue = false;
            this.sorteoeditedIndex = this.grupoSorteos.indexOf(item);
            this.sorteoeditedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemSorteosLimite(item) {
            this.snackValue = false;
            this.sorteoLimiteeditedIndex = this.grupoSorteosLimite.indexOf(item);
            this.sorteoLimiteeditedItem = Object.assign({}, item);
            this.dialogDeleteLimite = true;
        },

        deleteItemConfirmSorteos() {
            this.snackValue = false;
            this.grupoSorteos.splice(this.sorteoeditedIndex, 1);

            let me = this;
            //this.editedItem.cia = this.strCia;
            this.modeloSorteos.companyId = this.strCia;
            this.modeloSorteos.grupoSorteos = this.grupoSorteos;

            axios
                .post("/api/Tiempos/UpdateSorteosCia", this.modeloSorteos)
                .then(function () {
                    me.snackText = "Registro eliminado satisfactoriamente";
                    me.snackValue = true;
                    me.getSorteosAll();
                    //me.getData();
                })
                .catch(function (error) {
                    console.log(error);
                    // me.saved(false);
                });

            this.closeSorteos();

            this.closeDeleteSorteos();
        },

        deleteItemConfirmSorteosLimite() {
            this.snackValue = false;
            this.grupoSorteosLimite.splice(this.sorteoLimiteeditedIndex, 1);

            let me = this;

            axios
                .post("/api/Tiempos/DeleteLimiteNumeros", this.sorteoLimiteeditedItem)
                .then(function () {
                    me.snackText = "Registro eliminado satisfactoriamente";
                    me.snackValue = true;
                    //me.getData();
                })
                .catch(function (error) {
                    console.log(error);
                    // me.saved(false);
                });

            this.closeSorteosLimite();
        },

        saveSorteos() {
            this.addNewSorteos();
        },

        
        saveSorteosLimite() {
            this.addNewSorteosLimite();
        },

        changeSorteos() {
            this.getSorteo();
        },

        changeSorteosLimite() {
            this.getSorteoLimite();
        },

        getSorteo() {
            this.snackValue = false;
            let me = this;
            axios
                .get("api/Tiempos/GetGrupoSorteosById?id=" + this.sorteoDropDown, {
                    crossdomain: true,
                })
                .then(function (response) {
                    me.sorteoeditedItem = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getSorteoLimite() {
            this.snackValue = false;
            let me = this;
            axios
                .get("api/Tiempos/GetLimiteNumerosAll?companyId=" + this.strCia + "&idSorteo=" + this.sorteoLimiteDropDown, {
                    crossdomain: true,
                })
                .then(function (response) {
                    me.grupoSorteosLimite = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getModeloCia() {
            this.snackValue = false;
            let me = this;
            axios
                .get("api/Tiempos/GetModeloCia?companyId=" + this.strCia, {
                    crossdomain: true,
                })
                .then(function (response) {
                    me.grupoSorteos = response.data.grupoSorteos;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addNewSorteos() {
            this.snackValue = false;
            this.existeSorteo = false;
            this.grupoSorteos.find((object) => {
                if (object.id === this.sorteoDropDown) {
                    this.existeSorteo = true;
                }
            });

            if (this.existeSorteo === false) {
                if (this.sorteoeditedItem.id != "") {
                    if (this.sorteoeditedIndex > -1) {
                        // this.snackText = "Existe:" + this.existeCia;
                        // this.snackValue = true;
                    } else {
                        this.grupoSorteos.push(this.sorteoeditedItem);

                        let me = this;
                        //this.editedItem.cia = this.strCia;
                        this.modeloSorteos.companyId = this.strCia;
                        this.modeloSorteos.grupoSorteos = this.grupoSorteos;

                        axios
                            .post("/api/Tiempos/UpdateSorteosCia", this.modeloSorteos)
                            .then(function () {
                                me.snackText = "Registro modificado satisfactoriamente";
                                me.snackValue = true;
                                me.getSorteosAll();
                                //me.getData();
                            })
                            .catch(function (error) {
                                console.log(error);
                                // me.saved(false);
                            });

                        this.closeSorteos();

                        this.existeSorteo = false;
                    }
                    this.closeSorteos();
                }
            }
        },

        addNewSorteosLimite() {
            this.snackValue = false;
            this.existeSorteoLimite = false;
            // this.grupoSorteosLimite.find((object) => {
            //     if (object.id === this.sorteoLimiteDropDown) {
            //         this.existeSorteoLimite = true;
            //     }
            // });

            if (this.existeSorteoLimite === false) {
                if (this.sorteoLimiteeditedItem.id != "") {
                    if (this.sorteoLimiteeditedIndex > -1) {
                        // this.snackText = "Existe:" + this.existeCia;
                        // this.snackValue = true;
                    } else {
                        this.grupoSorteosLimite.push(this.sorteoLimiteeditedItem);

                        this.sorteoLimiteeditedItem.companyId = this.strCia;
                        this.sorteoLimiteeditedItem.idSorteo = this.sorteoLimiteDropDown;

                        let me = this;
                        axios
                            .post("/api/Tiempos/InsertLimiteNumeros", this.sorteoLimiteeditedItem)
                            .then(function () {
                                me.snackText = "Registro agregado satisfactoriamente";
                                me.snackValue = true;
                                //me.getData();
                            })
                            .catch(function (error) {
                                console.log(error);
                                // me.saved(false);
                            });

                        this.closeSorteosLimite();

                        this.existeSorteoLimite = false;
                    }
                    this.closeSorteosLimite();
                }
            }
        },

        closeSorteosLimite() {
            this.snackValue = false;
            this.dialog = false;
            this.dialogArticulos = false;
            this.dialogDeleteLimite = false;
            this.$nextTick(() => {
                this.sorteoLimiteeditedItem = Object.assign({}, this.sorteoLimitedefaultItem);
                this.sorteoLimiteeditedIndex = -1;
            });
        },

        closeSorteos() {
            this.snackValue = false;
            this.dialog = false;
            this.dialogArticulos = false;
            this.$nextTick(() => {
                this.sorteoeditedItem = Object.assign({}, this.sorteodefaultItem);
                this.sorteoeditedIndex = -1;
            });
        },

        editItem(item) {
            this.snackValue = false;
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.getSorteos();
            this.getModeloCia();
            //this.changeProvincia();
            //this.changeCantones();
            //this.changeDistritos();
            //this.getActividades();
            this.tab2 = 1;
            this.cancelarValid = true;
            this.eliminarValid = true;
            this.guardarValid = true;
            this.incluirValid = false;
        },

        save() {
            this.snackValue = false;
            let me = this;
            //this.editedItem.cia = this.strCia;
            this.modeloCia.cia = this.editedItem;
            this.modeloCia.grupoSorteos = this.grupoSorteos;

            if (this.editedIndex > -1) {
                axios
                    .post("/api/Tiempos/UpdateModeloCia", this.modeloCia)
                    .then(function () {
                        me.snackText = "Registro modificado satisfactoriamente";
                        me.snackValue = true;
                        me.getData();
                    })
                    .catch(function (error) {
                        console.log(error);
                        // me.saved(false);
                    });
            } else {
                axios
                    .post("/api/Tiempos/InsertModeloCia", this.modeloCia)
                    .then(function () {
                        me.snackText = "Registro agregado satisfactoriamente";
                        me.snackValue = true;
                        me.getData();
                    })
                    .catch(function (error) {
                        console.log(error);
                        // me.saved(false);
                    });
            }
            this.cancelarValid = true;
            this.eliminarValid = true;
            this.guardarValid = true;
            this.incluirValid = false;
            this.snackValue = false;
            //this.changeEsReceptor();
        },

        deleteItem() {
            this.snackValue = false;
            let me = this;
            if (confirm("¿Está seguro de que desea eliminar el articulo?")) {
                axios
                    .delete("api/Tiempos/deleteClientes?cia=" + this.editedItem.cedula)
                    .then(function () {
                        me.getData();
                    })
                    .catch(function (error) {
                        console.log(error);
                        // me.saved(false);
                    });
                this.cerrar();
                this.tab2 = 0;
                this.esRec = false;
            }
        },

        closed() {
            this.snackValue = false;
            this.showNewData = false;
            this.dialog = false;
        },

        cerrar() {
            this.snackValue = false;
            this.dialog = false;
            this.$nextTick(() => {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.valid = false;
                this.cancelarValid = false;
                this.eliminarValid = false;
                this.guardarValid = false;
                this.incluirValid = true;
            });
        },

        onImageChange(ev) {
            this.tempFile = ev;
            this.editedItem.certificado = ev.name;
        },

        onChangeCiaImage(ev) {
            this.tempFileImage = ev;
            this.editedItem.imagePath = ev.name;
        },

        incluir() {
            this.$nextTick(() => {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.cancelarValid = true;
                this.eliminarValid = false;
                this.guardarValid = true;
                this.incluirValid = false;
                this.esRec = false;
                this.getSorteos();
            });
        },
    },
};
</script>

<style>
.cd {
    overflow: scroll;
}

.v-tabs-items {
    overflow: auto;
}

.botonSalir {
    position: fixed;
    top: 5px;
    right: 5px;
}
</style>