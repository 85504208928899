<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Reporte Ventas
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-col cols="12" sm="12" md="12">
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>Filtros</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12" sm="7" md="7">
                          <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="dates" persistent
                            width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="dates" label="Periodo" prepend-icon="mdi-calendar" readonly
                                required :rules="dateRules" v-bind="attrs" v-on="on" class="pa-0"
                                hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="dateModal = false">
                                Cancel
                              </v-btn>
                              <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col col="12" md="3" sm="3">
                          <v-select label="Usuarios" item-text="nombre" item-value="cedula" v-model="reporte.cedula"
                            :items="usuarios" :disabled="!validUsuarios" class="pa-0"></v-select>
                        </v-col>
                        <!-- <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="reporte.cedula"
                            label="Cédula Usuario"
                            class="pa-0"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="2" md="2">
                          <v-btn color="primary" class="pa-0" @click="getReport()" :disabled="!valid">
                            Generar
                          </v-btn>
                        </v-col>

                        <!-- <v-col cols="12" sm="2" md="2">
                          <v-btn color="primary" class="pa-0" @click="verNumerosVendidos()">
                            Ver Numeros
                          </v-btn>
                        </v-col> -->

                        <v-dialog transition="dialog-bottom-transition" v-model="dialogReport">
                          <div v-if="showNewData">
                            <VerNumerosVendidos2 v-bind:allwedOnly="true" v-bind:numerosInfo="numerosInfo"
                              @closed="closed" />
                          </div>
                        </v-dialog>

                      </v-row>
                    </v-form>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2" height="40">
          <v-tabs-slider></v-tabs-slider>
          <v-tab key="sorteos"> Sorteos </v-tab>
          <v-tab key="detalles"> Detalles </v-tab>
        </v-tabs>

        <v-col cols="12" sm="12" md="12">
          <v-card>
            <v-tabs-items v-model="tab2" class="ti">
              <v-tab-item key="sorteos">
                <v-data-table :headers="headers" :items="items">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-flash-red-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item key="detalles">
                <v-data-table :headers="headers2" :items="items2">
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>

        <!-- <v-data-table :headers="headers" :items="items"> </v-data-table> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VerNumerosVendidos2 from "./VerNumerosVendidos2.vue";

export default {
  components: {
    VerNumerosVendidos2,
  },
  data() {
    return {
      dialogReport: false,
      showNewData: false,
      valid: false,
      validUsuarios: false,
      validDecargar: false,
      dateModal: false,
      dates: [],
      items: [],
      items2: [],
      usuarios: [],
      modeloReporteVentas: {
        sorteos: [],
        detalles: [],
      },
      numerosInfo: {
        idUsuario: "",
        idSorteo: "",
        fechaInicio: new Date(),
        fechaFin: new Date(),
      },
      tab2: 0,
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      headers: [
        // {
        //   text: "Nombre",
        //   align: "start",
        //   value: "nombre",
        // },
        {
          text: "Sorteo",
          align: "start",
          value: "sorteo",
        },
        {
          text: "Total Comisión",
          align: "start",
          value: "comision",
        },
        {
          text: "Total Venta",
          align: "start",
          value: "monto",
        },
        { text: "Números Vendidos", value: "actions", width: 50, sortable: false },
      ],
      headers2: [
        {
          text: "Sorteo",
          align: "start",
          value: "sorteo",
        },
        {
          text: "Usuario",
          align: "start",
          value: "nombre",
        },
        {
          text: "# Tiquete",
          align: "start",
          value: "idTiquete",
        },
        {
          text: "Cliente",
          align: "start",
          value: "cliente",
        },
        {
          text: "Números",
          align: "start",
          value: "numeros",
        },
        {
          text: "Total",
          align: "start",
          value: "total",
        },
      ],
      tiqueteInfo: {
        idTiquete: "",
        tipoTiquete: "",
      },
      reporte: {
        cedula: "",
        fechaInicio: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
        fechaFin: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
      },
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  created() {
    this.reporte.cedula = this.user.cedula;
    this.getUsuariosPorCia();
  },

  methods: {
    getReport() {
      this.getReporteVentas();
      //this.getRecibidos();
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.verNumerosVendidos();
    },

    verNumerosVendidos() {
      // this.numerosInfo.idSorteo = 1;
      // this.numerosInfo.fechaInicio = this.dates[0].toString();
      // this.numerosInfo.fechaFin = this.dates[1].toString();

      this.numerosInfo.idSorteo = this.editedItem.idSorteo;
      this.numerosInfo.fechaInicio = this.editedItem.fecha;
      this.numerosInfo.fechaFin = this.editedItem.fecha;
      this.numerosInfo.idUsuario = this.reporte.cedula;
      //this.numerosInfo.modeloReporteVentas = this.modeloReporteVentas;
      this.dialogReport = true;
      this.showNewData = true;
    },

    getUsuariosPorCia() {
      let me = this;
      axios
        .get(
          "api/Usuarios/getUsuariosPorCia?companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.usuarios = response.data;
          console.log(me.user.cedula);
          const myUser = me.usuarios.find(user => user.cedula === me.user.cedula);
          console.log(myUser.tipo);
          if (myUser.tipo === true) {
            me.reporte.cedula = "0";
            me.validUsuarios = true;
          } else {
            me.reporte.cedula = myUser.cedula;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getReporteVentas() {
      let me = this;
      axios
        .get(
          "api/Tiempos/GetReporteVentas?idUsuario=" +
          this.reporte.cedula +
          "&fechaInicio=" +
          this.dates[0].toString() +
          "&fechaFin=" +
          this.dates[1].toString() +
          "&companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.modeloReporteVentas = response.data;
          me.items = me.modeloReporteVentas.sorteos;
          me.items2 = me.modeloReporteVentas.detalles;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRecibidos() {
      let me = this;
      axios
        .get(
          "api/Reportes/getFacturasRecibidas?cia=" +
          this.strCia +
          "&fechaInicio=" +
          this.dates[0].toString() +
          "&fechaFin=" +
          this.dates[1].toString()
        )
        .then(function (response) {
          me.items2 = response.data;
          me.validDecargar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    closed() {
      this.customerModal = false;
      this.showNewData = false;
      this.dialogReport = false;
    },
  },
};
</script>

<style>
.actionsCol .v-btn {
  margin-right: 10px;
}
</style>