<template>
    <div class="renderPage">
        <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2">
            <v-tabs-slider></v-tabs-slider>
            <v-tab key="tiquetes_registrados" @click="limpiar()">
                Pedidos Registrados
                <v-icon>mdi-content-save</v-icon>
            </v-tab>
            <v-tab key="tiquetes_nuevos" @click="limpiar()">
                Pedido
                <v-icon>mdi-pencil-box</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab2" class="ti">
            <v-tab-item key="tiquetes_registrados">
                <v-spacer></v-spacer>
                <v-card>
                    <v-col cols="12" sm="12" md="12"> </v-col>
                    <v-data-table :headers="headers" :items="items" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-magnify"
                                    label="Búsqueda" single-line hide-details dense>
                                </v-text-field>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
                        </template></v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item key="tiquetes_nuevos">
                <v-form v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="12">
                                <v-expansion-panels multiple v-model="panel">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Seleccionar Cliente</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-container>
                                                <v-row>
                                                    <!-- <v-col col="12" md="3" sm="3">
                                                        <v-select label="Cliente" item-text="nombre"
                                                            item-value="celular" v-model="celular" :items="clientes"
                                                            class="pa-0" :disabled="!elegirValid"
                                                            @change="changeValueComponent"></v-select>
                                                    </v-col> -->

                                                    <!-- <v-col cols="12" sm="3" md="3">
                                                        <v-text-field type="text" v-model="fecha" label="Fecha"
                                                            class="pa-0" readonly outlined dense></v-text-field>
                                                    </v-col> -->

                                                    <v-col cols="12" sm="3" md="3">
                                                        <v-text-field label="Celular" v-model="editedItem.clienteCod"
                                                            class="pa-0" outlined dense readonly>
                                                        </v-text-field>
                                                    </v-col>

                                                    <v-col align="center" cols="12" sm="2" md="2">
                                                        <v-btn color="primary" @click="mostrarBuscarClientes"
                                                            class="pa-0" :disabled="!btnAgregarCliente">
                                                            <v-icon>mdi-file-find</v-icon>
                                                        </v-btn>
                                                    </v-col>

                                                    <v-dialog persistent transition="dialog-bottom-transition"
                                                        v-model="dialogReport" max-width="380">
                                                        <div v-if="showNewData">
                                                            <TiqueteKAAM v-bind:allwedOnly="true"
                                                                v-bind:tiqueteInfo="tiqueteInfo" @closed="closed" />
                                                        </div>
                                                    </v-dialog>

                                                    <v-dialog transition="dialog-bottom-transition" v-model="dialog">
                                                        <div v-if="showNewData">
                                                            <BuscarClientes v-bind:allwedOnly="true" @clicked="selected"
                                                                @closed="closed" />
                                                        </div>
                                                    </v-dialog>

                                                    <v-col cols="12" sm="5" md="5">
                                                        <v-text-field class="pa-0" v-model="editedItem.clienteNombre"
                                                            label="Nombre" outlined dense readonly></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>

                            <v-tabs background-color="primary" icons-and-text fixed-tabs center-active
                                v-model="tabFactura" height="40">
                                <v-tabs-slider color="white"></v-tabs-slider>
                                <v-tab key="detalle" @click="limpiar()">
                                    Lineas de detalle
                                </v-tab>
                                <v-tab key="abono" @click="limpiar()"> Abonos </v-tab>
                            </v-tabs>

                            <v-col cols="12" sm="12" md="12">
                                <v-card>
                                    <v-tabs-items v-model="tabFactura">
                                        <v-tab-item key="detalle">
                                            <v-data-table hide-default-footer :headers="headersDetalle"
                                                :items="lineasDetalle" :items-per-page="-1">
                                                <template v-slot:top>
                                                    <v-toolbar height="48">
                                                        <v-spacer></v-spacer>
                                                        <template>
                                                            <v-dialog transition="dialog-bottom-transition"
                                                                v-model="dialogBuscarArticulos">
                                                                <div v-if="showNewDataArti">
                                                                    <BuscarArticulos v-bind:allwedOnly="true"
                                                                        @clicked="selectedArticulos" @closed="closed" />
                                                                </div>
                                                            </v-dialog>
                                                            <v-btn color="green" class="pa-0"
                                                                @click="mostrarBuscarArticulos">
                                                                <v-icon color="white">mdi-cart-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-dialog v-model="dialogArticulos" max-width="700px">

                                                            <v-card>
                                                                <v-card-title>
                                                                    <span class="text-h5">{{ formTitle }}</span>
                                                                </v-card-title>

                                                                <v-card-text>
                                                                    <v-container>
                                                                        <v-row>
                                                                            <v-col cols="12" sm="5" md="3">
                                                                                <v-text-field
                                                                                    v-model="detalleEditedItem.codigoArticulo"
                                                                                    outlined dense label="Código"
                                                                                    readonly></v-text-field>
                                                                            </v-col>

                                                                            <v-col cols="12" sm="2" md="2">
                                                                                <v-btn color="primary" class="pa-0"
                                                                                    @click="mostrarBuscarArticulos">
                                                                                    <v-icon>mdi-file-find</v-icon>
                                                                                </v-btn>
                                                                            </v-col>

                                                                            <v-dialog
                                                                                transition="dialog-bottom-transition"
                                                                                v-model="dialogBuscarArticulos">
                                                                                <div v-if="showNewDataArti">
                                                                                    <BuscarArticulos
                                                                                        v-bind:allwedOnly="true"
                                                                                        @clicked="selectedArticulos"
                                                                                        @closed="closed" />
                                                                                </div>
                                                                            </v-dialog>

                                                                            <v-col cols="12" sm="4" md="3">
                                                                                <v-text-field
                                                                                    v-model="detalleEditedItem.cantidad"
                                                                                    type="number" label="Cantidad"
                                                                                    step="any" min="0" outlined
                                                                                    dense></v-text-field>
                                                                            </v-col>
                                                                            <v-col cols="12" sm="8" md="6">
                                                                                <v-text-field v-model="detalleEditedItem.descripcion
                                                                                    " label="Descripción" outlined
                                                                                    dense></v-text-field>
                                                                            </v-col>
                                                                            <v-col cols="12" sm="5" md="3">
                                                                                <v-text-field
                                                                                    v-model="detalleEditedItem.precio"
                                                                                    label="Precio" type="number"
                                                                                    step="any" min="0" outlined
                                                                                    dense></v-text-field>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>

                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>

                                                                    <v-btn color="primary" class="mb-2" @click="close">
                                                                        Cancelar
                                                                    </v-btn>
                                                                    <v-btn color="primary" class="mb-2"
                                                                        @click="saveDetalleArticulo">
                                                                        Agregar
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                                            <v-card>
                                                                <v-card-title class="text-h5">Desea descartar el
                                                                    articulo?</v-card-title>
                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" class="mb-2"
                                                                        @click="closeDelete">Cancelar</v-btn>
                                                                    <v-btn color="primary" class="mb-2"
                                                                        @click="deleteItemConfirm">OK</v-btn>
                                                                    <v-spacer></v-spacer>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-btn color="primary" class="pa-0" @click="detalleEditItem(item)">
                                                        <v-icon color="white"> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:[`item.delete`]="{ item }">
                                                    <v-btn color="red" class="pa-0" @click="deleteItem(item)">
                                                        <v-icon color="white"> mdi-delete </v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </v-tab-item>

                                        <v-tab-item key="abonos">
                                            <v-data-table hide-default-footer :headers="headersAbono"
                                                :items="lineasAbono" :items-per-page="-1">
                                                <template v-slot:top>
                                                    <v-toolbar height="48">
                                                        <v-spacer></v-spacer>
                                                        <template>
                                                            <!-- <v-dialog transition="dialog-bottom-transition"
                                                                v-model="dialogBuscarArticulos">
                                                                <div v-if="showNewDataArti">
                                                                    <BuscarArticulos v-bind:allwedOnly="true"
                                                                        @clicked="selectedArticulos" @closed="closed" />
                                                                </div>
                                                            </v-dialog> -->
                                                            <v-btn color="green" class="pa-0"
                                                                @click="mostrarBuscarAbonos">
                                                                <v-icon color="white">mdi-cart-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-dialog v-model="dialogAbonos" max-width="700px">

                                                            <v-card>
                                                                <v-card-title>
                                                                    <span class="text-h5">Abonos</span>
                                                                </v-card-title>

                                                                <v-card-text>
                                                                    <v-container>
                                                                        <v-row>
                                                                            <v-col cols="12" sm="8" md="6">
                                                                                <v-text-field v-model="abonoEditedItem.descripcion
                                                                                    " label="Descripción" outlined
                                                                                    dense></v-text-field>
                                                                            </v-col>
                                                                            <v-col cols="12" sm="5" md="3">
                                                                                <v-text-field
                                                                                    v-model="abonoEditedItem.totalLinea"
                                                                                    label="Total" type="number"
                                                                                    step="any" min="0" outlined
                                                                                    dense></v-text-field>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>

                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>

                                                                    <v-btn color="primary" class="mb-2" @click="close">
                                                                        Cancelar
                                                                    </v-btn>
                                                                    <v-btn color="primary" class="mb-2"
                                                                        @click="saveDetalleAbono">
                                                                        Agregar
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-dialog v-model="dialogDeleteAbono" max-width="500px">
                                                            <v-card>
                                                                <v-card-title class="text-h5">Desea descartar el
                                                                    abono?</v-card-title>
                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" class="mb-2"
                                                                        @click="closeDeleteAbono">Cancelar</v-btn>
                                                                    <v-btn color="primary" class="mb-2"
                                                                        @click="deleteItemConfirmAbono">OK</v-btn>
                                                                    <v-spacer></v-spacer>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-btn color="primary" class="pa-0" @click="abonoEditItem(item)">
                                                        <v-icon color="white"> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:[`item.delete`]="{ item }">
                                                    <v-btn color="red" class="pa-0" @click="deleteItemAbono(item)">
                                                        <v-icon color="white"> mdi-delete </v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </v-tab-item>
                                    </v-tabs-items>
                                    <v-container>
                                        <v-row>
                                            <v-expansion-panels multiple v-model="panelResumen">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>Resumen Pedido
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col cols="12" sm="3" md="3">
                                                                    <v-text-field v-model="editedItem.saldoInicial"
                                                                        label="Saldo Inicial" step="any" min="0"
                                                                        readonly class="right-input pa-0" outlined
                                                                        dense></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" sm="3" md="3">
                                                                    <v-text-field v-model="editedItem.abonado"
                                                                        label="Total Abonos" step="any" min="0.00"
                                                                        readonly class="right-input pa-0" outlined
                                                                        dense></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" sm="3" md="3">
                                                                    <v-text-field v-model="editedItem.total"
                                                                        label="Saldo Actual" step="any" min="0" readonly
                                                                        class="right-input pa-0" outlined
                                                                        dense></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div>
                        <v-expansion-panels multiple v-model="panel"> </v-expansion-panels>
                    </div>

                    <v-container>
                        <v-row align="center" justify="center">
                            <v-col cols="auto">
                                <v-btn color="primary" :disabled="!incluirValid" @click="incluir()"
                                    density="compact">Incluir</v-btn>
                            </v-col>

                            <v-col cols="auto">
                                <v-btn color="primary" :disabled="!guardarValid" @click="save()"
                                    density="comfortable">Guardar</v-btn>
                            </v-col>

                            <v-col cols="auto">
                                <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()"
                                    density="default">Cancelar</v-btn>
                            </v-col>

                            <v-col cols="auto">
                                <v-btn color="primary" @click="saveCompartir()" density="default">Compartir</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-tab-item>
            <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
        </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
import BuscarClientes from "./BuscarClientes.vue";
import BuscarArticulos from "./BuscarArticulos.vue";
import TiqueteKAAM from "./TiqueteKAAM.vue";

export default {
    components: {
        Snackbar,
        BuscarClientes,
        BuscarArticulos,
        TiqueteKAAM,
    },
    // dark: true,
    data() {
        return {
            btnAgregarCliente: false,
            btnAgregarArti: false,
            iconVisible: false,
            iconVisibleCedula: false,
            visibleBtnConsultar: true,
            visibleProgress: false,
            dialog: false,
            dialogArticulos: false,
            dialogAbonos: false,
            valid: false,
            incluirValid: true,
            consultarEstadoValid: false,
            cancelarValid: false,
            elegirValid: false,
            //reenviarValid: false,
            imprimirValid: false,
            guardarValid: false,
            search: "",
            celular: "",
            snackText: "",
            snackValue: false,
            fecha: new Date().toISOString().slice(0, 10),
            tab2: 0,
            tabFactura: 0,
            dialogDelete: false,
            dateModal: false,
            dates: [],
            datesFormat: [],
            panel: [],
            panelResumen: [],
            headers: [
                // {
                //     text: "Código Pedido",
                //     align: "start",
                //     value: "id",
                // },
                // {
                //     text: "Fecha",
                //     align: "start",
                //     value: "fecha",
                // },
                {
                    text: "Cliente",
                    align: "start",
                    value: "clienteNombre",
                },
                {
                    text: "Saldo Actual",
                    align: "start",
                    value: "total",                    
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
            ],
            headersDetalle: [
                {
                    text: "Cant",
                    align: "start",
                    value: "cantidad",
                },
                {
                    text: "Descripción",
                    align: "start",
                    value: "descripcion",
                },
                {
                    text: "Precio",
                    align: "start",
                    value: "precio",
                },
                {
                    text: "Total",
                    align: "start",
                    value: "totalLinea",
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
                { text: "Eliminar", value: "delete", width: 70, sortable: false },
            ],
            headersAbono: [
                {
                    text: "Descripción",
                    align: "start",
                    value: "descripcion",
                },
                {
                    text: "Total",
                    align: "start",
                    value: "totalLinea",
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
                { text: "Eliminar", value: "delete", width: 70, sortable: false },
            ],
            items: [],
            cliente: [],
            articulo: [],
            totales: [],
            lineasDetalle: [],
            lineasAbono: [],
            respuestaHacienda: [],
            comprobante: [],
            editedIndex: -1,
            tiqueteInfo: {
                idTiquete: "",
            },
            dialogReport: false,
            modelFactura: {
                factura: [],
                detalles: [],
                abonos: [],
            },
            editedItem: {
                id: 0,
                fecha: new Date().toISOString().slice(0, 10),
                clienteCod: "",
                total: 0,
                abonado: 0,
                saldoInicial: 0,
            },
            defaultItem: {
                id: 0,
                fecha: new Date().toISOString().slice(0, 10),
                clienteCod: "",
                total: 0,
                abonado: 0,
                saldoInicial: 0,
            },
            detalleditedIndex: -1,
            detalleEditedItem: {
                numLinea: 0,
                cantidad: 0,
                articuloCodigo: "",
                descripcion: "",
                precio: 0,
                totalLinea: 0,
            },
            detalleDefaultItem: {
                numLinea: 0,
                cantidad: 0,
                codigoArticulo: "",
                descripcion: "",
                precio: 0,
                totalLinea: 0,
            },
            abonoditedIndex: -1,
            abonoEditedItem: {
                numLinea: 0,
                descripcion: "",
                totalLinea: 0,
            },
            abonoDefaultItem: {
                numLinea: 0,
                descripcion: "",
                totalLinea: 0,
            },
            showNewData: false,
            showNewDataArti: false,
            nameRules: [(v) => !!v || "Campo requerido"],
            tempFile: "",
            modelFile: null,
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
        formTitle() {
            return this.editedIndex === -1 ? "Agregar Articulo" : "Editar Articulo";
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },

        dialogArticulos(val) {
            val || this.close();
        },

        search() {
            this.snackValue = false;
        },
    },

    created() {
        this.setDates();
        this.getData();
        //this.getClientes();
    },

    methods: {
        signalChange: function (evt) {
            this.snackText = "Si llega al escribir" + evt;
            this.snackValue = true;
        },

        changeText() {
            this.iconVisible = true;
        },

        activarIconoCedula() {
            this.iconVisibleCedula = true;
        },

        getClientes() {
            //this.clientes = [];
            let me = this;
            axios
                .get("api/KAAM/GetClientes")
                .then(function (response) {
                    me.clientes = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getClientesAll() {
            //this.clientes = [];
            let me = this;
            axios
                .get("api/KAAM/GetClientesAll")
                .then(function (response) {
                    me.clientes = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getData() {
            let me = this;
            axios
                .get("api/KAAM/getFacturas", {
                    crossdomain: true,
                }
                )
                .then(function (response) {
                    me.items = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        printDate: function () {
            return new Date().tolocal;
        },

        getTiqueteReport() {
            this.dialogReport = true;
            this.showNewData = true;
        },

        setDates() {
            var date = new Date();
            //var dateInicio = new Date(date.getFullYear(), date.getMonth(), 1);
            var dateInicio = new Date(date.getFullYear(), date.getMonth(), 1);
            //var dateFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.dates[0] = dateInicio.toISOString().substr(0, 10);
            this.dates[1] = date.toISOString().substr(0, 10);

            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var dt = date.getDate();

            if (dt < 10) {
                dt = "0" + dt;
            }
            if (month < 10) {
                month = "0" + month;
            }

            var yearInicio = dateInicio.getFullYear();
            var monthInicio = dateInicio.getMonth() + 1;
            var dtInicio = dateInicio.getDate();

            if (dtInicio < 10) {
                dtInicio = "0" + dtInicio;
            }
            if (monthInicio < 10) {
                monthInicio = "0" + monthInicio;
            }

            this.datesFormat[0] = dtInicio + "-" + monthInicio + "-" + yearInicio;
            this.datesFormat[1] = dt + "-" + month + "-" + year;
        },

        setDatesFormat() {
            //eslint-disable-next-line
            var date = new Date(
                this.dates[0].toString().replace(/-/g, "/").replace(/T.+/, "")
            );

            //eslint-disable-next-line
            var date2 = new Date(
                this.dates[1].toString().replace(/-/g, "/").replace(/T.+/, "")
            );

            var year = date2.getFullYear();
            var month = date2.getMonth() + 1;
            var dt = date2.getDate();

            if (dt < 10) {
                dt = "0" + dt;
            }
            if (month < 10) {
                month = "0" + month;
            }

            var yearInicio = date.getFullYear();
            var monthInicio = date.getMonth() + 1;
            var dtInicio = date.getDate();

            if (dtInicio < 10) {
                dtInicio = "0" + dtInicio;
            }
            if (monthInicio < 10) {
                monthInicio = "0" + monthInicio;
            }

            this.datesFormat[0] = dtInicio + "-" + monthInicio + "-" + yearInicio;
            this.datesFormat[1] = dt + "-" + month + "-" + year;
        },

        mostrarBuscarClientes() {
            this.snackValue = false;
            this.dialog = true;
            this.showNewData = true;
            this.cancelarValid = true;
        },

        mostrarBuscarArticulos() {
            this.snackValue = false;
            this.dialogArticulos = false;
            this.dialogBuscarArticulos = true;
            this.showNewDataArti = true;
            this.cancelarValid = true;
        },

        mostrarBuscarAbonos() {
            this.snackValue = false;
            this.dialogAbonos = true;
            //this.showNewDataArti = true;
            this.cancelarValid = true;
        },

        editItem(item) {
            //this.getClientesAll();
            this.snackValue = false;
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.getModeloComprobante();
            this.tab2 = 1;
            this.elegirValid = false;
            this.cancelarValid = true;
            this.eliminarValid = true;
            this.guardarValid = true;
            this.incluirValid = false;
            this.panel = [0];
            this.panelResumen = [0];
        },

        detalleEditItem(item) {
            if (this.guardarValid) {
                this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
                this.detalleEditedItem = Object.assign({}, item);
                this.dialogArticulos = true;
            }
        },

        abonoEditItem(item) {
            if (this.guardarValid) {
                this.abonoEditedIndex = this.lineasAbono.indexOf(item);
                this.abonoEditedItem = Object.assign({}, item);
                this.dialogAbonos = true;
            }
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.detalleEditedIndex = -1;
            });
        },

        closeDeleteAbono() {
            this.dialogDeleteAbono = false;
            this.$nextTick(() => {
                this.abonoEditedItem = Object.assign({}, this.abonoDefaultItem);
                this.abonoEditedIndex = -1;
            });
        },

        deleteItemConfirm() {
            if (this.guardarValid) {
                this.lineasDetalle.splice(this.detalleEditedIndex, 1);
                this.closeDelete();

                let me = this;

                axios
                    .post(
                        "api/KAAM/realizarCalculos",
                        this.lineasDetalle
                    )
                    .then(function (response) {
                        me.lineasDetalle = response.data.detalles;
                        me.editedItem.total = me.lineasDetalle.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.total = me.editedItem.total - me.editedItem.abonado;
                        me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    })
                    .catch(function (error) {
                        console.log(error);
                        // me.saved(false);
                    });
            }
        },

        deleteItemConfirmAbono() {
            if (this.guardarValid) {
                this.lineasAbono.splice(this.abonoEditedIndex, 1);
                this.closeDeleteAbono();

                let me = this;

                axios
                    .post(
                        "api/KAAM/realizarCalculosAbonos",
                        this.lineasAbono
                    )
                    .then(function (response) {
                        me.lineasAbono = response.data.abonos;
                        me.editedItem.total = me.lineasDetalle.reduce((sum, item) => sum + (item.totalLinea || 0), 0) - me.lineasAbono.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.abonado = me.lineasAbono.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    })
                    .catch(function (error) {
                        console.log(error);
                        // me.saved(false);
                    });
            }
        },

        getModeloComprobante() {
            this.snackValue = false;
            let me = this;

            axios
                .get(
                    "api/KAAM/getModeloComprobante?id=" +
                    this.editedItem.id
                )
                .then(function (response) {
                    me.modelFactura = response.data;
                    me.editedItem = me.modelFactura.factura;
                    me.lineasDetalle = me.modelFactura.detalles;
                    me.lineasAbono = me.modelFactura.abonos;
                    me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    me.celular = me.editedItem.clienteCod;
                    me.tiqueteInfo.idTiquete = me.editedItem.id;
                    console.log(me.tiqueteInfo.idTiquete);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        save() {
            this.snackValue = false;
            let me = this;
            this.visibleProgress = true;
            this.editedItem.fecha = new Date();
            this.modelFactura.factura = this.editedItem;
            this.modelFactura.detalles = this.lineasDetalle;
            this.modelFactura.abonos = this.lineasAbono;
            this.editedItem.clienteCod = this.celular;

            if (this.editedIndex > -1) {
                axios
                    .post("api/KAAM/insertFactura", this.modelFactura)
                    .then(function (response) {
                        me.snackText = "Pedido actualizado correctamente";
                        me.snackValue = true;
                        //me.visibleProgress = false;
                        me.tiqueteInfo.idTiquete = response.data;
                        me.editedItem.id = response.data;
                        //me.respuestaHacienda = response.data;
                        me.getData();
                        //me.getClientesAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                axios
                    .post("api/KAAM/insertFactura", this.modelFactura)
                    .then(function (response) {
                        //me.visibleProgress = false;
                        me.snackText = "Registro agregado correctamente";
                        me.snackValue = true;
                        me.tiqueteInfo.idTiquete = response.data;
                        me.editedItem.id = response.data;
                        //me.respuestaHacienda = response.data;
                        me.getData();
                        //me.getClientesAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            this.cancelarValid = true;
            //this.eliminarValid = true;
            this.guardarValid = true;
            this.incluirValid = false;
            this.snackValue = false;
            this.btnAgregarArti = false;
            this.btnAgregarCliente = false;
            this.elegirValid = false;
            //this.tab2 = 0;
        },

        saveCompartir() {
            this.snackValue = false;
            let me = this;
            this.visibleProgress = true;
            this.editedItem.fecha = new Date();
            this.modelFactura.factura = this.editedItem;
            this.modelFactura.detalles = this.lineasDetalle;
            this.modelFactura.abonos = this.lineasAbono;
            this.editedItem.clienteCod = this.celular;

            if (this.editedIndex > -1) {
                axios
                    .post("api/KAAM/insertFactura", this.modelFactura)
                    .then(function (response) {
                        me.snackText = "Pedido actualizado correctamente";
                        me.snackValue = true;
                        //me.visibleProgress = false;
                        me.tiqueteInfo.idTiquete = response.data;
                        //me.respuestaHacienda = response.data;
                        me.getData();
                        me.getTiqueteReport();
                        //me.getClientesAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                axios
                    .post("api/KAAM/insertFactura", this.modelFactura)
                    .then(function (response) {
                        //me.visibleProgress = false;
                        me.snackText = "Registro agregado correctamente";
                        me.snackValue = true;
                        me.tiqueteInfo.idTiquete = response.data;
                        //me.respuestaHacienda = response.data;
                        me.getData();
                        me.getTiqueteReport();
                        //me.getClientesAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            this.cancelarValid = true;
            //this.eliminarValid = true;
            this.guardarValid = true;
            this.incluirValid = false;
            this.snackValue = false;
            this.btnAgregarArti = false;
            this.btnAgregarCliente = false;
            this.elegirValid = false;
            //this.tab2 = 0;
        },

        saveDetalleArticulo() {
            if (this.detalleEditedIndex > -1) {
                Object.assign(
                    this.lineasDetalle[this.detalleEditedIndex],
                    this.detalleEditedItem
                );
            } else {
                this.lineasDetalle.push(this.detalleEditedItem);
            }
            this.close();

            let me = this;
            if (this.detalleEditedIndex > -1) {
                axios
                    .post(
                        "api/KAAM/realizarCalculos",
                        this.lineasDetalle
                    )
                    .then(function (response) {
                        me.lineasDetalle = response.data.detalles;
                        me.editedItem.total = me.lineasDetalle.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.total = me.editedItem.total - me.editedItem.abonado;
                        me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                axios
                    .post(
                        "api/KAAM/realizarCalculos",
                        this.lineasDetalle
                    )
                    .then(function (response) {
                        me.lineasDetalle = response.data.detalles;
                        me.editedItem.total = me.lineasDetalle.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.total = me.editedItem.total - me.editedItem.abonado;
                        me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            this.cancelarValid = true;
            this.imprimirValid = false;
            //this.reenviarValid = false;
            this.verPDF = false;
            this.verXMLFirmado = false;
            this.verXMLRespuesta = false;
            this.guardarValid = true;
            this.incluirValid = false;
            this.snackValue = false;
            this.panelResumen = [0];
            this.consultarEstadoValid = false;
        },

        saveDetalleAbono() {
            if (this.abonoEditedIndex > -1) {
                Object.assign(
                    this.lineasAbono[this.abonoEditedIndex],
                    this.abonoEditedItem
                );
            } else {
                this.lineasAbono.push(this.abonoEditedItem);
            }
            this.closeAbono();

            let me = this;
            if (this.abonoEditedIndex > -1) {
                axios
                    .post(
                        "api/KAAM/realizarCalculosAbonos",
                        this.lineasAbono
                    )
                    .then(function (response) {
                        me.lineasAbono = response.data.abonos;
                        me.editedItem.total = me.lineasDetalle.reduce((sum, item) => sum + (item.totalLinea || 0), 0) - me.lineasAbono.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.abonado = me.lineasAbono.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                axios
                    .post(
                        "api/KAAM/realizarCalculosAbonos",
                        this.lineasAbono
                    )
                    .then(function (response) {
                        me.lineasAbono = response.data.abonos;
                        me.editedItem.total = me.lineasDetalle.reduce((sum, item) => sum + (item.totalLinea || 0), 0) - me.lineasAbono.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.abonado = me.lineasAbono.reduce((sum, item) => sum + (item.totalLinea || 0), 0);
                        me.editedItem.saldoInicial = me.editedItem.total + me.editedItem.abonado;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            this.cancelarValid = true;
            this.imprimirValid = false;
            //this.reenviarValid = false;
            this.guardarValid = true;
            this.incluirValid = false;
            this.snackValue = false;
            this.panelResumen = [0];
            this.consultarEstadoValid = false;
        },

        close() {
            this.snackValue = false;
            this.dialogArticulos = false;
            this.elegirValid = false;
            this.$nextTick(() => {
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.detalleEditedIndex = -1;
            });
        },

        closeAbono() {
            this.snackValue = false;
            this.dialogAbonos = false;
            this.elegirValid = false;
            this.$nextTick(() => {
                this.abonoEditedItem = Object.assign({}, this.abonoDefaultItem);
                this.abonoEditedIndex = -1;
            });
        },

        deleteItem(item) {
            if (this.guardarValid) {
                this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
                this.detalleEditedItem = Object.assign({}, item);
                this.dialogDelete = true;
            }
        },

        deleteItemAbono(item) {
            if (this.guardarValid) {
                this.abonoEditedIndex = this.lineasAbono.indexOf(item);
                this.abonoEditedItem = Object.assign({}, item);
                this.dialogDeleteAbono = true;
            }
        },

        closed() {
            this.snackValue = false;
            this.showNewData = false;
            this.showNewDataArti = false;
            this.dialog = false;
            this.dialogArticulos = false;
            this.dialogReport = false;
            this.dialogSinpe = false;
            this.dialogBuscarArticulos = false;
            this.dialogFormaReenvio = false;
        },

        selected(value) {
            this.cliente = Object.assign({}, value);
            this.showNewData = false;
            this.dialog = false;
            this.dialogArticulos = false;
            this.celular = this.cliente.celular;
            this.editedItem.clienteCod = this.cliente.celular;
            this.editedItem.clienteNombre = this.cliente.nombre;
            this.btnAgregarArti = true;
        },

        selectedArticulos(value) {
            this.articulo = Object.assign({}, value);
            this.showNewDataArti = false;
            this.dialogArticulos = true;
            this.detalleEditedItem.factura = this.editedItem.codigoFact;
            this.detalleEditedItem.numLinea = 0;
            this.detalleEditedItem.totalLinea = 0;
            this.detalleEditedItem.precio = this.articulo.precio;
            this.detalleEditedItem.descripcion = this.articulo.nombre;
            this.detalleEditedItem.cantidad = 1;
            this.detalleEditedItem.codigoArticulo = this.articulo.codigo;
        },

        limpiar() {
            this.snackValue = false;
        },

        cerrar() {
            this.snackValue = false;
            this.dialog = false;
            this.dialogArticulos = false;
            //this.getClientesAll();
            this.$nextTick(() => {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.valid = false;
                this.cancelarValid = false;
                this.imprimirValid = false;
                //this.reenviarValid = false;
                this.guardarValid = false;
                this.incluirValid = true;
                //this.consultarEstadoValid = false;
                this.panelResumen = [];
                this.panel = [];
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.lineasDetalle = [];
                this.abonoEditedItem = Object.assign({}, this.abonoDefaultItem);
                this.lineasAbono = [];
                this.btnAgregarArti = false;
                this.btnAgregarCliente = false;
            });
        },

        onImageChange(ev) {
            this.tempFile = ev;
            this.editedItem.certificado = ev.name;
        },

        getFechaActual() {
            let me = this;
            axios
                .get("api/Facturacion/getFechaActualString")
                .then(function (response) {
                    me.fecha = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        incluir() {
            this.snackValue = false;
            //this.getClientes();
            //this.clientes = [];
            this.$nextTick(() => {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                //this.getCodigoTiquete();
                this.cancelarValid = true;
                this.imprimirValid = false;
                //this.reenviarValid = false;
                this.verPDF = false;
                this.verXMLFirmado = false;
                this.verXMLRespuesta = false;
                this.guardarValid = true;
                this.incluirValid = true;
                //this.getClientes();
                this.consultarEstadoValid = false;
                // this.getCia();
                // this.getMonedas();
                // this.getActividades();
                // this.getCondicionVenta();
                // this.getMedioPago();
                // this.getSucursales();
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.lineasDetalle = [];
                this.abonoEditedItem = Object.assign({}, this.abonoDefaultItem);
                this.lineasAbono = [];
                this.panel = [0];
                this.btnAgregarCliente = true;
                //this.editedItem.clienteCod = "CL-00001";
                //this.BuscarClienteCodigo();
                this.btnAgregarArti = true;
                this.elegirValid = true;
            });
        },
    },
};
</script>

<style>
.cd {
    overflow: scroll;
}

.v-tabs-items {
    overflow: auto;
}

.botonSalir {
    position: fixed;
    top: 5px;
    right: 5px;
}

.right-input input {
    text-align: right;
}

/* table td { border-top: solid gold 1px; }  */

/* td.second {
  background-color: gold;
} */
</style>