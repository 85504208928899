import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Layout from '../views/Layout.vue';
import Tiempos from '../components/Tiempos.vue'
import CerrarSorteo from '../components/CerrarSorteo.vue'
import BuscarTiquete from '../components/BuscarTiquete.vue'
import EliminarTiquete from '../components/EliminarTiquete.vue'
import ReporteVentas from '../components/ReporteVentas.vue'
import Cias from '../components/Cias.vue'
import ReporteComision from '../components/ReporteComision.vue'
import Resultados from '../components/Resultados.vue'
import Canjear from '../components/Canjear.vue'
import ReporteControlVentas from '../components/ReporteControlVentas.vue'
import ReporteHistoricoPremios from '../components/ReporteHistoricoPremios.vue'
import Usuarios from '../components/Usuarios.vue'
import ExportarLista from '../components/ExportarLista.vue'
import UsuariosEditar from '../components/UsuariosEditar.vue'
import SorteosParam from '../components/SorteosParam.vue'
import CerrarSorteoManual from '../components/CerrarSorteoManual.vue'
import ListaVentas from '../components/ListaVentas.vue'

//KAAM
import FacturasKAAM from '../components/FacturasKAAM.vue'
import Clientes from '../components/Clientes.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Layout,
    children: [
      {
        path: '/resultados',
        name: 'Resultados',
        component: Resultados
      },
      {
        path: '/tiempos',
        name: 'Tiempos',
        component: Tiempos
      },  
      {
        path: '/canjear',
        name: 'Canjear',
        component: Canjear
      },
      {
        path: '/cerrarsorteo',
        name: 'CerrarSorteo',
        component: CerrarSorteo
      },    
      {
        path: '/buscartiquete',
        name: 'BuscarTiquete',
        component: BuscarTiquete
      }, 
      {
        path: '/eliminartiquete',
        name: 'EliminarTiquete',
        component: EliminarTiquete
      },
      {
        path: '/reporteventas',
        name: 'ReporteVentas',
        component: ReporteVentas
      },
      {
        path: '/reportecontrolventas',
        name: 'ReporteControlVentas',
        component: ReporteControlVentas
      },
      {
        path: '/reportehistoricopremios',
        name: 'ReporteHistoricoPremios',
        component: ReporteHistoricoPremios
      },
      {
        path: '/exportarlista',
        name: 'ExportarLista',
        component: ExportarLista
      },
      {
        path: '/cias',
        name: 'Cias',
        component: Cias
      },
      {
        path: '/reportecomision',
        name: 'ReporteComision',
        component: ReporteComision
      },
      {
        path: '/facturaskaam',
        name: 'FacturasKAAM',
        component: FacturasKAAM
      },
      {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes
      },
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios
      },
      {
        path: '/usuarioseditar',
        name: 'UsuariosEditar',
        component: UsuariosEditar
      },
      {
        path: '/sorteosparam',
        name: 'SorteosParam',
        component: SorteosParam
      },
      {
        path: '/cerrarsorteomanual',
        name: 'CerrarSorteoManual',
        component: CerrarSorteoManual
      },  
      {
        path: '/listaventas',
        name: 'ListaVentas',
        component: ListaVentas
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
