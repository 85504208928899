<template dark>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Editar Usuario
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="editedItem.cedula"
            label="Cedula"
            class="pa-0"
            readonly
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="editedItem.clave"
            label="Clave"
            class="pa-0"
            :disabled="incluirValid"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            label="Contraseña"
            class="input-group--focused pa-0"
            v-model="editedItem.clave"
            :disabled="incluirValid"
            @click:append="show3 = !show3"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="editedItem.nombre"
            label="Nombre"
            :rules="nameRules"
            required
            class="pa-0"
            :disabled="incluirValid"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn color="primary" :disabled="!guardarValid" @click="save()">
            <v-icon left> mdi-content-save </v-icon>
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      show3: false,
      valid: false,
      guardarValid: true,
      snackText: "",
      snackValue: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        cedula: "",
        clave: "",
        tipo: 0,
        nombre: "",
        ciaPorDefecto: "",
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.editedItem = Object.assign({}, this.editedItem);
    this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Usuarios/getUsuario?usuarioId=" + this.user.id, {
          crossdomain: true,
        })
        .then(function (response) {
          me.editedItem = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    save() {
      let me = this;

      axios
        .post("api/Usuarios/updateUser", this.editedItem)
        .then(function () {
          me.snackText = "Registro modificado satisfactoriamente";
          me.snackValue = true;
          me.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>