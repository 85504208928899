<template>
    <div class="renderPage">
        <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
            <v-spacer />
            <v-toolbar-title class="text-capitalize">
                Reporte Lista Ventas
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" to="/home">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <v-row>

                    <v-col cols="12" sm="12" md="12">
                        <v-expansion-panels multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Filtros</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container>
                                        <v-row>
                                            <v-form ref="form" v-model="valid">
                                                <v-row>
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-dialog ref="dialog" v-model="dateModal"
                                                            :return-value.sync="dates" persistent width="290px">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="dates" label="Periodo"
                                                                    prepend-icon="mdi-calendar" readonly required
                                                                    :rules="dateRules" v-bind="attrs" v-on="on"
                                                                    class="pa-0" hide-details></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="dates" range>
                                                                <v-spacer></v-spacer>
                                                                <v-btn text color="primary" @click="dateModal = false">
                                                                    Cancel
                                                                </v-btn>
                                                                <v-btn text color="primary"
                                                                    @click="$refs.dialog.save(dates)">
                                                                    OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-dialog>
                                                    </v-col>

                                                    <v-col col="12" md="6" sm="6">
                                                        <v-select label="Sorteo" item-text="descripcion" item-value="id"
                                                            v-model="idSorteo" :items="sorteos"
                                                            @change="onSelectedCategory" outlined dense
                                                            hide-details></v-select>
                                                    </v-col>

                                                    <v-col col="12" md="3" sm="3">
                                                        <v-select label="Puesto" item-text="ciaNombre"
                                                            item-value="ciaId" v-model="cia" :items="companies"
                                                            :disabled="!validCompany" @change="changeCompanies"
                                                            class="pa-0"></v-select>
                                                    </v-col>
                                                    <v-col col="12" md="3" sm="3">
                                                        <v-select label="Usuarios" item-text="nombre"
                                                            item-value="cedula" v-model="reporte.cedula"
                                                            :items="usuarios" :disabled="!validUsuarios"
                                                            class="pa-0"></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="2" md="2">
                                                        <v-btn color="primary" class="pa-0" @click="getReport()"
                                                            :disabled="!valid">
                                                            Generar
                                                        </v-btn>
                                                    </v-col>

                                                    <v-col cols="12" sm="2" md="2">
                                                        <v-btn color="primary" class="pa-0" @click="getTiqueteReport"
                                                            :disabled="!valid">
                                                            Compartir
                                                        </v-btn>
                                                    </v-col>

                                                    <v-dialog persistent transition="dialog-bottom-transition"
                                                        v-model="dialogReport" max-width="400">
                                                        <div v-if="showNewData">
                                                            <TiqueteReporte v-bind:allwedOnly="true"
                                                                v-bind:tiqueteInfo="tiqueteInfo" @closed="closed" />
                                                        </div>
                                                    </v-dialog>

                                                    <!-- <v-dialog persistent transition="dialog-bottom-transition"
                                                        v-model="dialogReport" max-width="380">
                                                        <div v-if="showNewData">
                                                            <template>
                                                                <v-container fluid>
                                                                    <v-card max-width="380">
                                                                        <div v-html="htmlReporte" ref="element"
                                                                            id="ticket"></div>
                                                                        <v-card-actions>
                                                                            <v-spacer></v-spacer>

                                                                            <v-btn color="primary" hide-details
                                                                                @click="share">
                                                                                <v-icon left> mdi-share-variant
                                                                                </v-icon>
                                                                            </v-btn>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn color="primary" hide-details
                                                                                @click="print">
                                                                                <v-icon left> mdi-printer </v-icon>
                                                                            </v-btn>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn color="primary" hide-details
                                                                                @click="printTicket">
                                                                                <v-icon left> mdi-bluetooth </v-icon>
                                                                            </v-btn>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn color="primary" hide-details
                                                                                @click="selected">
                                                                                <v-icon left> mdi-replay </v-icon>
                                                                            </v-btn>
                                                                        </v-card-actions>
                                                                        <v-card-actions>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn color="error" @click="close()">
                                                                                <v-icon left> mdi-close </v-icon>
                                                                                Cerrar
                                                                            </v-btn>
                                                                        </v-card-actions>
                                                                    </v-card>
                                                                </v-container>
                                                            </template>
                                                        </div>
                                                    </v-dialog> -->

                                                </v-row>
                                            </v-form>
                                        </v-row>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>

                <v-row ref="element">
                    <v-col cols="12" sm="2" md="2">
                        <v-text-field v-model="ventaNormal" label="Venta Normal" step="any" min="0" readonly
                            class="pa-0" underlined dense></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2" md="2">
                        <v-text-field v-model="ventaReventado" label="Venta Reventado" step="any" min="0" readonly
                            class="pa-0" underlined dense></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2" md="2">
                        <v-text-field v-model="ventaTotal" label="Venta Total" step="any" min="0" readonly
                            class="pa-0 red-text" underlined dense
                            :style="{ fontWeight: 'bold', color: 'red' }"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2" md="2">
                        <v-text-field v-model="numGanador" label="Número Ganador" step="any" min="0" readonly
                            class="pa-0 red-text" underlined dense
                            :style="{ fontWeight: 'bold', color: 'red' }"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                        <v-data-table :headers="headersX" :items="datos" class="custom-bordered-table"
                            :items-per-page="-1" hide-default-footer>
                            <!-- Personaliza la columna 'Número' -->
                            <template v-slot:item.Numero1="{ item }">
                                <span
                                    :style="{ color: item.Numero1 === numGanador ? 'red' : 'inherit', fontWeight: item.Numero1 === numGanador ? 'bold' : 'inherit' }">
                                    {{ item.Numero1 }}
                                </span>
                            </template>
                            <template v-slot:item.Numero2="{ item }">
                                <span
                                    :style="{ color: item.Numero2 === numGanador ? 'red' : 'inherit', fontWeight: item.Numero2 === numGanador ? 'bold' : 'inherit' }">
                                    {{ item.Numero2 }}
                                </span>
                            </template>
                            <template v-slot:item.Numero3="{ item }">
                                <span
                                    :style="{ color: item.Numero3 === numGanador ? 'red' : 'inherit', fontWeight: item.Numero3 === numGanador ? 'bold' : 'inherit' }">
                                    {{ item.Numero3 }}
                                </span>
                            </template>
                            <template v-slot:item.Numero4="{ item }">
                                <span
                                    :style="{ color: item.Numero4 === numGanador ? 'red' : 'inherit', fontWeight: item.Numero4 === numGanador ? 'bold' : 'inherit' }">
                                    {{ item.Numero4 }}
                                </span>
                            </template>
                            <template v-slot:item.Numero5="{ item }">
                                <span
                                    :style="{ color: item.Numero5 === numGanador ? 'red' : 'inherit', fontWeight: item.Numero5 === numGanador ? 'bold' : 'inherit' }">
                                    {{ item.Numero5 }}
                                </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import TiqueteReporte from "./TiqueteReporte.vue";

export default {
    components: {
        TiqueteReporte,
    },
    data() {
        return {
            dialogReport: false,
            showNewData: false,
            valid: false,
            isRed: false,
            validUsuarios: false,
            validCompany: false,
            validDecargar: false,
            dateModal: false,
            dates: [],
            items: [],
            items2: [],
            usuarios: [],
            companies: [],
            sorteos: [],
            datos: [],
            datos2: [],
            bloques: [],
            tablaFormateada: [],
            cia: "",
            htmlReporte: "",
            ventaNormal: 0,
            ventaReventado: 0,
            ventaTotal: 0,
            numGanador: 0,
            modeloReporteVentas: {
                sorteos: [],
                detalles: [],
            },
            tiqueteInfo: {
                datos: [],
            },
            numerosInfo: {
                idUsuario: "",
                idSorteo: "",
                fechaInicio: new Date(),
                fechaFin: new Date(),
            },
            tab2: 0,
            dateRules: [
                (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
            ],
            headersX: [
                { text: 'Número', value: 'Numero1' },
                { text: 'Monto', value: 'Monto1' },
                { text: 'Reventado', value: 'Reventado1' },
                { text: 'Número', value: 'Numero2' },
                { text: 'Monto', value: 'Monto2' },
                { text: 'Reventado', value: 'Reventado2' },
                { text: 'Número', value: 'Numero3' },
                { text: 'Monto', value: 'Monto3' },
                { text: 'Reventado', value: 'Reventado3' },
                { text: 'Número', value: 'Numero4' },
                { text: 'Monto', value: 'Monto4' },
                { text: 'Reventado', value: 'Reventado4' },
                { text: 'Número', value: 'Numero5' },
                { text: 'Monto', value: 'Monto5' },
                { text: 'Reventado', value: 'Reventado5' },
            ],
            headers: [
                // {
                //   text: "Nombre",
                //   align: "start",
                //   value: "nombre",
                // },
                {
                    text: "Sorteo",
                    align: "start",
                    value: "sorteo",
                },
                {
                    text: "Total Comisión",
                    align: "start",
                    value: "comision",
                },
                {
                    text: "Total Venta",
                    align: "start",
                    value: "monto",
                },
                { text: "Números Vendidos", value: "actions", width: 50, sortable: false },
            ],
            headers2: [
                {
                    text: "Sorteo",
                    align: "start",
                    value: "sorteo",
                },
                {
                    text: "Usuario",
                    align: "start",
                    value: "nombre",
                },
                {
                    text: "# Tiquete",
                    align: "start",
                    value: "idTiquete",
                },
                {
                    text: "Cliente",
                    align: "start",
                    value: "cliente",
                },
                {
                    text: "Números",
                    align: "start",
                    value: "numeros",
                },
                {
                    text: "Total",
                    align: "start",
                    value: "total",
                },
            ],
            reporte: {
                cedula: "",
                fechaInicio: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
                fechaFin: new Date().toLocaleString("en-US", { timeZone: "America/Costa_Rica" }),
            },
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
    },

    created() {
        this.reporte.cedula = this.user.cedula;
        this.getCompanies();
        this.cia = this.user.ciaPorDefecto;
        this.getUsuariosPorCia();
        this.getSorteos();
    },

    methods: {
        ...mapMutations(["setStrCia"]),

        getReport() {
            this.getReporteVentas();
            //this.getRecibidos();
        },

        share() {
            // iife here
            (async () => {
                if (!("share" in navigator)) {
                    console.log("Sharing not supported");
                    return;
                }
                // `element` is the HTML element you want to share.
                // `backgroundColor` is the desired background color.
                const canvas = await html2canvas(this.$refs.element);

                canvas.toBlob(async (blob) => {
                    // Even if you want to share just one file you need to
                    // send them as an array of files.
                    const files = [new File([blob], "image.png", { type: blob.type })];
                    const shareData = {
                        text: "",
                        title: "",
                        files,
                    };
                    if (navigator.canShare(shareData)) {
                        try {
                            await navigator.share(shareData);
                        } catch (err) {
                            if (err.name !== "AbortError") {
                                console.error(err.name, err.message);
                            }
                        }
                    } else {
                        console.warn("Sharing not supported", shareData);
                    }
                });
            })();
        },

        getTiqueteReport() {
            this.dialogReport = true;
            this.showNewData = true;
        },

        editItem(item) {
            this.snackValue = false;
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.verNumerosVendidos();
        },

        highlightNumber(value) {
            // Devuelve true si el número es 5, 10 o 15
            return value === 5 || value === 10 || value === 15;
        },

        changeCompanies() {
            this.getUsuariosPorCia();
        },

        getCompanies() {
            let me = this;
            me.setStrCia(this.user.ciaPorDefecto);
            axios
                .get("api/Usuarios/getCiasPorUsuarioV2?usuarioId=" + this.user.id)
                .then(function (response) {
                    me.companies = response.data;
                    if (me.companies.length > 2) {
                        if (me.user.tipo) {
                            me.reporte.cedula = "0";
                            me.cia = "0";
                            me.validCompany = true;
                        } else {
                            me.reporte.cedula = this.user.id;
                            //me.cia = "0";
                            me.validCompany = false;
                        }
                    } else {
                        me.reporte.cedula = this.user.id;
                        me.validCompany = false;
                    }

                    me.cia = this.user.ciaPorDefecto;
                    //me.ciaOld = this.user.ciaPorDefecto;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        verNumerosVendidos() {
            // this.numerosInfo.idSorteo = 1;
            // this.numerosInfo.fechaInicio = this.dates[0].toString();
            // this.numerosInfo.fechaFin = this.dates[1].toString();

            this.numerosInfo.idSorteo = this.editedItem.idSorteo;
            this.numerosInfo.fechaInicio = this.editedItem.fecha;
            this.numerosInfo.fechaFin = this.editedItem.fecha;
            this.numerosInfo.idUsuario = this.reporte.cedula;
            //this.numerosInfo.modeloReporteVentas = this.modeloReporteVentas;
            this.dialogReport = true;
            this.showNewData = true;
        },

        getSorteos() {
            let me = this;
            axios
                .get("api/Tiempos/GetSorteosAllV3?companyId=" + this.cia)
                .then(function (response) {
                    me.sorteos = response.data;
                    me.idSorteo = me.sorteos[0]["id"];
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getUsuariosPorCia() {
            let me = this;
            axios
                .get(
                    "api/Usuarios/getUsuariosPorCia?companyId=" +
                    this.cia,
                )
                .then(function (response) {
                    me.usuarios = response.data;
                    console.log(me.user.cedula);
                    const myUser = me.usuarios.find(user => user.cedula === me.user.cedula);
                    console.log(myUser.tipo);
                    if (myUser.tipo === true) {
                        me.reporte.cedula = "0";
                        me.validUsuarios = true;
                    } else {
                        me.reporte.cedula = myUser.cedula;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getReporteVentas() {
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetNumerosVendidosV2?idUsuario=" +
                    this.user.id +
                    "&fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString() +
                    "&companyId=" +
                    this.cia +
                    "&idSorteo=" +
                    this.idSorteo +
                    "&idUsuarioBanca=" +
                    this.reporte.cedula,
                )
                .then(function (response) {
                    const data = response.data;

                    me.datos2 = data;
                    me.tiqueteInfo.datos = me.datos2;

                    me.ventaNormal = data.ventaNormal;
                    me.ventaReventado = data.ventaReventado;
                    me.ventaTotal = data.ventaTotal;
                    me.numGanador = data.numGanador;

                    me.GenerarTiqueteReporteListaVentas();

                    me.datos = data.detalles.map(item => ({
                        Numero1: item[0],
                        Monto1: item[1],
                        Reventado1: item[2],
                        Numero2: item[3],
                        Monto2: item[4],
                        Reventado2: item[5],
                        Numero3: item[6],
                        Monto3: item[7],
                        Reventado3: item[8],
                        Numero4: item[9],
                        Monto4: item[10],
                        Reventado4: item[11],
                        Numero5: item[12],
                        Monto5: item[13],
                        Reventado5: item[14],
                    }))
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        GenerarTiqueteReporteListaVentas() {
            console.log(this.datos2);
            this.snackValue = false;
            let me = this;
            axios
                .post(
                    "api/Tiempos/GenerarTiqueteReporteListaVentas",
                    this.datos2
                )
                .then(function (response) {
                    me.htmlReporte = response.data;
                    //console.log(me.tiqueteReport.bancaNombre);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        generarTabla() {
            // Dividir datos en bloques de rangos específicos
            const rangos = [
                { inicio: 1, fin: 19 },
                { inicio: 20, fin: 39 },
                { inicio: 40, fin: 59 },
                { inicio: 60, fin: 79 },
                { inicio: 80, fin: 99 },
            ];

            this.bloques = rangos.map((rango) =>
                this.datos.detalles.filter(
                    (detalle) =>
                        parseInt(detalle.numero) >= rango.inicio &&
                        parseInt(detalle.numero) <= rango.fin
                )
            );

            // Preparar las filas y columnas
            const maxFilas = Math.max(...this.bloques.map((bloque) => bloque.length));

            this.tablaFormateada = Array.from({ length: maxFilas }, (_, filaIndex) => {
                return this.bloques.flatMap((bloque) => {
                    const dato = bloque[filaIndex] || { numero: "", monto: "", montoReventado: "" };
                    return [dato.numero, dato.monto, dato.montoReventado];
                });
            });
        },

        getRecibidos() {
            let me = this;
            axios
                .get(
                    "api/Reportes/getFacturasRecibidas?cia=" +
                    this.strCia +
                    "&fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString()
                )
                .then(function (response) {
                    me.items2 = response.data;
                    me.validDecargar = true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        closed() {
            this.customerModal = false;
            this.showNewData = false;
            this.dialogReport = false;
        },
    },
};
</script>

<style>
.actionsCol .v-btn {
    margin-right: 10px;
}

.custom-bordered-table {
    border: 1px solid #ccc;
    /* Color del borde */
    border-radius: 8px;
    /* Bordes redondeados */
}

.custom-bordered-table table {
    border-collapse: collapse;
    /* Evita doble borde entre celdas */
}

.custom-bordered-table th,
.custom-bordered-table td {
    border: 1px solid #ccc;
    /* Bordes internos de la tabla */
}

.red-text {
    color: red !important;
}
</style>