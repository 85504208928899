<template dark>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize"> CERRAR SORTEO </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col col="12" md="3" sm="3">
          <v-select
            label="Sorteos"
            item-text="descripcion"
            item-value="id"
            v-model="editedItem.idSorteo"
            :items="sorteos"
            @change="changeValueComponent"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="editedItem.numero"
            label="Número Ganador"
            @change="changeValueComponent"
            @input="changeValueComponent"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn color="primary" hide-details @click="cerrarSorteo()">
            <v-icon left> mdi-checkbox-marked </v-icon>
            Cerrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      snackText: "",
      snackValue: false,
      editedIndex: -1,
      sorteos: [],
      respuestaCerrarSorteo: [],
      defaultItem: {
        idSorteo: 0,
        numero: 0,
      },
      editedItem: {
        idSorteo: 0,
        numero: 0,
      },
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.editedItem = Object.assign({}, this.editedItem);
    this.getSorteos();
  },

  methods: {
    getSorteos() {
      //this.changeValueComponent();
      let me = this;
      axios
        .get("api/Tiempos/GetSorteosPorCerrar?companyId=" + this.strCia)
        .then(function (response) {
          me.sorteos = response.data;
          me.editedItem.idSorteo = me.sorteos[0]["id"];
          //me.getRestringidos();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    cerrarSorteo() {
      this.changeValueComponent();
      this.editedItem.idUsuario = this.user.cedula;
      let me = this;
      //if (this.editedIndex > -1) {
      axios
        .get(
          "api/Tiempos/CerrarSorteosPorIdConFecha?idSorteo=" +
            this.editedItem.idSorteo +
            "&numero=" +
            this.editedItem.numero +
            "&companyId=" +
            this.strCia
        )
        .then(function (response) {
          me.respuestaCerrarSorteo = response.data;
          //me.respuestaNumeros = response.data;
          me.snackText = me.respuestaCerrarSorteo;
          me.snackValue = true;
          me.getSorteos();
        })
        .catch(function (error) {
          console.log(error);
          me.snackText = "Ha ocurrido un error inesperado";
          me.snackValue = true;
        });
      //}
    },

    changeValueComponent() {
      this.snackValue = false;
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialogReport = false;
    },

    save() {
      //this.snackValue = false;
      this.editedItem.idUsuario = this.user.cedula;
      let me = this;

      if (this.numeros.length < 1) {
        me.snackText = "Por favor seleccione los números a vender";
        me.snackValue = true;
      } else {
        axios
          .post("api/Tiempos/InsertTiquetes", this.editedItem)
          .then(function (response) {
            me.tiqueteInfo.idTiquete = response.data;
            me.snackText =
              "Se ha registrado el tiquete #" +
              response.data +
              " correctamente";
            me.snackValue = true;
            me.editedItem.numeros = "";
            me.editedItem.monto = 0;
            me.editedItem.total = 0;
            me.numeros = [];
            me.restringidos = "";
            me.getTiqueteReport();
            me.getSorteos();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.text-input-blue .v-text-field__slot input {
  color: red !important;
}
</style>