<template dark>
  <div class="renderPage">
    <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2">
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="usuarios_registrados" @click="limpiar()">
        Usuarios Registrados
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="usuarios_nuevos" @click="limpiar()">
        Usuarios
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="usuarios_registrados">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-magnify" label="Búsqueda"
                single-line hide-details dense>
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="usuarios_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="editedItem.cedula" label="Cedula" class="pa-0"
                  :disabled="incluirValid"></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="editedItem.clave" label="Clave" class="pa-0"
                  :disabled="incluirValid"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="editedItem.nombre" label="Nombre" :rules="nameRules" required class="pa-0"
                  :disabled="incluirValid"></v-text-field>
              </v-col>

              <v-col col="12" md="4" sm="4">
                <v-select label="Banca Defecto" item-text="nombre" item-value="companyId"
                  v-model="editedItem.ciaPorDefecto" :items="cias" class="pa-0" :disabled="incluirValid"></v-select>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="editedItem.porcRegular" label="Porcentaje Comisión" class="pa-0"
                  :disabled="incluirValid"></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-checkbox v-model="editedItem.tipo" label="Admin" class="pa-0" :disabled="incluirValid">
                </v-checkbox>
              </v-col>

              <v-col cols="12" sm="3" md="12">
                <v-expansion-panels multiple v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Seleccionar Compañias</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col col="12" md="6" sm="6">
                            <v-select label="Banca" item-text="nombre" item-value="companyId" v-model="ciaDropDown"
                              :items="cias" class="pa-0" @change="changeCia" :disabled="incluirValid"></v-select>
                          </v-col>

                          <v-col align="center" cols="12" sm="6" md="3">
                            <v-btn color="primary" dark class="mb-2" @click="saveCias" :disabled="incluirValid">
                              Agregar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-data-table :headers="headersCias" :items="companies" :search="search">
                        <template v-slot:top>
                          <v-toolbar height="30">
                            <!-- <v-spacer></v-spacer> -->
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Desea descartar la compañia?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="primary" class="mb-2" @click="closeDeleteCias">Cancelar</v-btn>
                                  <v-btn color="primary" class="mb-2" @click="deleteItemConfirmCias">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                          <v-btn color="red" class="pa-0" @click="deleteItemCias(item)">
                            <v-icon color="white"> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Seguridad</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col col="12" md="3" sm="3">
                            <v-select label="Menu" item-text="titulo" item-value="id" v-model="menuDropDown"
                              :items="menus" class="pa-0" @change="getMenuOpciones" :disabled="incluirValid"></v-select>
                          </v-col>

                          <v-col col="12" md="3" sm="3">
                            <v-select label="Paginas" item-text="titulo" item-value="id" v-model="menuOpcionesDropDown"
                              :items="menuOpciones" class="pa-0" @change="GetMenuOpcion"
                              :disabled="incluirValid"></v-select>
                          </v-col>

                          <v-col align="center" cols="12" sm="6" md="3">
                            <v-btn color="primary" dark class="mb-2" @click="savePaginas" :disabled="incluirValid">
                              Agregar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-data-table :headers="headersPaginas" :items="paginas" :search="search">
                        <template v-slot:top>
                          <v-toolbar height="30">
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogDeletePagina" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Desea descartar la pagina?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="primary" class="mb-2" @click="closeDeletePaginas">Cancelar</v-btn>
                                  <v-btn color="primary" class="mb-2" @click="deleteItemConfirmPagina">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                          <v-btn color="red" class="pa-0" @click="deleteItemPagina(item)">
                            <v-icon color="white"> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn color="primary" :disabled="!eliminarValid" @click="deleteItem()">
              Eliminar
            </v-btn>
            <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()" dark>
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import Cabys from "./Cabys.vue";

export default {
  components: {
    Snackbar,
    //Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogDeletePagina: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      esRec: false,
      existeCia: false,
      existePagina: false,
      ciaDropDown: "",
      menuDropDown: "",
      menuOpcionesDropDown: "",
      companies: [],
      menus: [],
      menuOpciones: [],
      paginas: [],
      panel: [],
      modeloPaginaUsuario: {
        usuario: [],
        companies: [],
        paginas: [],
      },
      headers: [
        {
          text: "Cédula",
          align: "start",
          value: "cedula",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      headersCias: [
        {
          text: "Cédula",
          align: "start",
          value: "companyId",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      headersPaginas: [
        {
          text: "Menu",
          align: "start",
          value: "menuNombre",
        },
        {
          text: "Página",
          align: "start",
          value: "titulo",
        },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      items: [],
      cias: [],
      tiposIdentificacion: [],
      monedas: [],
      provincias: [],
      cantones: [],
      distritos: [],
      barrios: [],
      codigoArticulo: "",
      editedIndex: -1,
      editedItem: {
        id: 0,
        cedula: "",
        clave: "",
        tipo: 0,
        nombre: "",
        ciaPorDefecto: "",
        porcRegular: 0,
      },
      defaultItem: {
        id: 0,
        cedula: "",
        clave: "",
        tipo: 0,
        nombre: "",
        ciaPorDefecto: "",
        porcRegular: 0,
      },
      clienteHacienda: {
        nombre: "",
        tipoIdentificacion: "",
        situacion: {
          estado: "",
        },
      },
      ciaeditedIndex: -1,
      ciaeditedItem: {
        nombre: "",
        companyId: "",
      },
      ciadefaultItem: {
        nombre: "",
        companyId: "",
      },
      paginaditedIndex: -1,
      paginaeditedItem: {
        id: 0,
        titulo: "",
        menuId: 0,
        path: "",
        menuNombre: "",
      },
      paginadefaultItem: {
        id: 0,
        titulo: "",
        menuId: 0,
        path: "",
        menuNombre: "",
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
    //this.getTiposIdentificacion();
    //this.getMonedas();
    //this.getProvincias();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Usuarios/getUserAll", {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    limpiar() {
      this.snackValue = false;
    },

    deleteItemCias(item) {
      this.ciaeditedIndex = this.companies.indexOf(item);
      this.ciaeditedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemPagina(item) {
      this.paginaditedIndex = this.paginas.indexOf(item);
      this.paginaeditedItem = Object.assign({}, item);
      this.dialogDeletePagina = true;
    },

    deleteItemConfirmCias() {
      this.companies.splice(this.ciaeditedIndex, 1);
      this.closeDeleteCias();
    },

    deleteItemConfirmPagina() {
      this.paginas.splice(this.paginaditedIndex, 1);
      this.closeDeletePaginas();
    },

    closeCias() {
      this.dialog = false;
      this.$nextTick(() => {
        this.ciaeditedItem = Object.assign({}, this.ciadefaultItem);
        this.ciaeditedIndex = -1;
      });
    },

    closePaginas() {
      this.dialog = false;
      this.$nextTick(() => {
        this.paginaeditedItem = Object.assign({}, this.paginadefaultItem);
        this.paginaditedIndex = -1;
      });
    },

    closeDeleteCias() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.ciaeditedItem = Object.assign({}, this.ciadefaultItem);
        this.ciaeditedIndex = -1;
      });
    },

    closeDeletePaginas() {
      this.dialogDeletePagina = false;
      this.$nextTick(() => {
        this.paginaeditedItem = Object.assign({}, this.paginadefaultItem);
        this.paginaditedIndex = -1;
      });
    },

    changeCia() {
      this.getCia();
    },

    saveCias() {
      this.addNewCia();
    },

    savePaginas() {
      this.addNewPagina();
    },

    addNewCia() {
      this.existeCia = false;
      this.companies.find((object) => {
        if (object.companyId === this.ciaDropDown) {
          this.existeCia = true;
        }
      });

      if (this.existeCia === false) {
        if (this.ciaeditedItem.companyId != "") {
          if (this.ciaeditedIndex > -1) {
            // this.snackText = "Existe:" + this.existeCia;
            // this.snackValue = true;
          } else {
            this.companies.push(this.ciaeditedItem);
            this.existeCia = false;
          }
          this.closeCias();
        }
      }
    },

    addNewPagina() {
      this.existePagina = false;
      this.paginas.find((object) => {
        if (object.id === this.menuOpcionesDropDown) {
          this.existePagina = true;
        }
      });

      if (this.existePagina === false) {
        if (this.paginaeditedItem.titulo != "") {
          if (this.paginaditedIndex > -1) {
            // this.snackText = "Existe:" + this.existeCia;
            // this.snackValue = true;
          } else {
            this.paginas.push(this.paginaeditedItem);
            this.existePagina = false;
          }
          this.closePaginas();
        }
      }
    },

    getCia() {
      let me = this;
      axios
        .get("api/Tiempos/GetBancaById?companyId=" + this.ciaDropDown, {
          crossdomain: true,
        })
        .then(function (response) {
          me.ciaeditedItem = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCias() {
      let me = this;
      axios
        .get("api/Tiempos/getBancas", {
          crossdomain: true,
        })
        .then(function (response) {
          me.cias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMenu() {
      let me = this;
      axios
        .get("api/Usuarios/getMenu", {
          crossdomain: true,
        })
        .then(function (response) {
          me.menus = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMenuOpciones() {
      let me = this;
      axios
        .get("api/Usuarios/getMenuOpciones?menuId=" + this.menuDropDown, {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.menuOpciones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    GetMenuOpcion() {
      let me = this;
      axios
        .get("api/Usuarios/getMenuOpcion?id=" + this.menuOpcionesDropDown, {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.paginaeditedItem = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    getPaginas() {
      let me = this;
      axios
        .get("api/Usuarios/getMenuOpciones?menuId=0", {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.paginas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    getCodigoCliente() {
      let me = this;
      axios
        .get("api/Clientes/getCodigoCliente?cia=" + this.strCia)
        .then(function (response) {
          //me.codigoArticulo = response.data;
          me.editedItem.codigo = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiposIdentificacion() {
      let me = this;
      axios
        .get("api/General/getTiposIdentificacion")
        .then(function (response) {
          me.tiposIdentificacion = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeEsReceptor() {
      // this.snackText = "entra y trae: " + this.editedItem.esReceptor;
      // this.snackValue = true;
      if (this.editedItem.esReceptor === true) {
        this.esRec = true;
      } else {
        this.esRec = false;
      }
    },

    getBarrios() {
      let me = this;
      axios
        .get(
          "api/General/getBarrios?provincia=" +
          this.editedItem.provincia +
          "&canton=" +
          this.editedItem.canton +
          "&distrito=" +
          this.editedItem.distrito
        )
        .then(function (response) {
          me.barrios = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeDistritos() {
      this.getBarrios();
    },

    getDistritos() {
      let me = this;
      axios
        .get(
          "api/General/getDistritos?provincia=" +
          this.editedItem.provincia +
          "&canton=" +
          this.editedItem.canton
        )
        .then(function (response) {
          me.distritos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeCantones() {
      this.getDistritos();
    },

    getCantones() {
      let me = this;
      axios
        .get("api/General/getCantones?provincia=" + this.editedItem.provincia)
        .then(function (response) {
          me.cantones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeProvincia() {
      this.getCantones();
    },

    getProvincias() {
      let me = this;
      axios
        .get("api/General/getProvincias")
        .then(function (response) {
          me.provincias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getClienteHacienda() {
      let me = this;
      axios
        .get(
          "https://api.hacienda.go.cr/fe/ae?identificacion=" +
          me.editedItem.cedula
        )
        .then(function (response) {
          me.clienteHacienda = response.data;
          me.editedItem.nombre = me.clienteHacienda.nombre;
          me.editedItem.nombreComercial = me.clienteHacienda.nombre;
          me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

          if (me.clienteHacienda.situacion.estado === "Inscrito") {
            me.editedItem.esReceptor = true;
            me.esRec = true;
            me.editedItem.areaTel = "506";
          } else {
            me.editedItem.esReceptor = false;
            me.esRec = false;
          }

          //   me.snackText = "estado hacienda"+ me.clienteHacienda.situacion.estado;
          //   me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.getCias();
      this.getMenu();
      //this.getPaginas();
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.panel = [0, 1];
      this.getModeloPaginaUsuario();

      // this.changeEsReceptor();
      // this.changeProvincia();
      // this.changeCantones();
      // this.changeDistritos();
      //this.changeCantones();
      //this.dialog = true;
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    // mostrarCabys() {
    //   this.dialog = true;
    //   this.showNewData = true;
    //   this.cancelarValid = true;
    // },

    save() {
      let me = this;

      this.modeloPaginaUsuario.usuario = this.editedItem;
      this.modeloPaginaUsuario.cias = this.companies;
      this.modeloPaginaUsuario.paginas = this.paginas;

      //this.editedItem.cia = this.strCia;
      if (this.editedIndex > -1) {
        axios
          .post("api/Usuarios/UpdateModeloPagina", this.modeloPaginaUsuario)
          .then(function () {
            me.snackText = "Registro modificado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      } else {
        axios
          .post("api/Usuarios/insertModeloPagina", this.modeloPaginaUsuario)
          .then(function () {
            me.snackText = "Registro agregado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
      this.snackValue = false;
    },

    getModeloPaginaUsuario() {
      let me = this;
      axios
        .get(
          "api/Usuarios/getModeloPaginaUsuario?usuarioId=" + this.editedItem.id,
          {
            crossdomain: true,
          }
        )
        .then(function (response) {
          me.modeloPaginaUsuario = response.data;
          //this.editedItem = this.editedItem;
          me.companies = me.modeloPaginaUsuario.cias;
          me.paginas = me.modeloPaginaUsuario.paginas;
          me.editedItem.porcRegular = me.modeloPaginaUsuario.usuario.porcRegular;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // validarCampos(){
    //     let me = this;

    //     if(this.editedItem.tipoArt === "") {
    //         return false;
    //     }
    //     else
    //     {
    //         return TRUE;
    //     }

    // },

    deleteItem() {
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el usuario?")) {
        axios
          .delete("api/Usuarios/deleteUser?usuarioId=" + this.editedItem.id)
          .then(function () {
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
        this.cerrar();
        this.tab2 = 0;
        this.esRec = false;
      }
    },

    // selected(value) {
    //   this.cabys = Object.assign({}, value);
    //   this.showNewData = false;
    //   this.dialog = false;
    //   //   this.snackText = "codigo: " + this.cabys.codigo + "descripcion: "+ this.cabys.descripcion + "impuesto: " +this.cabys.impuesto ;
    //   //   this.snackValue = true;
    //   this.editedItem.nombre = this.cabys.descripcion;
    //   this.editedItem.cabys = this.cabys.codigo;
    //   this.editedItem.tarifa = this.cabys.impuesto;
    // },

    closed() {
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
        this.panel = [];
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.menuOpciones = [];
        this.menuOpciones = [];
        this.menus = [];
        this.paginas = [];
        this.companies = [];

        //this.$refs.form.resetValidation();
      });
    },

    incluir() {
      //this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getCias();
        this.getMenu();
        this.getPaginas();
        this.panel = [0, 1];
        this.getCodigoCliente();
        //this.editedItem.areaTel = "506";
        //this.valid = false;
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.esRec = false;
        //this.$refs.form.resetValidation();
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>