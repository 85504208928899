import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      cedula: '',
      tipo: false,
      nombre: '',
    },
    strCia: "",
  },
  mutations: {
    setUser(state, value) {
      state.user = value
    },
    setStrCia(state, value) {
      state.strCia = value
    }
  },
  actions: {
  },
  getters: {
    user: state => state.user,
    strCia: state => state.strCia   
  }
})
