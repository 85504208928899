<template dark>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        {{ formTitle }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col col="12" md="5" sm="5">
          <v-select :label="sorteoTitle" item-text="descripcion" item-value="id" v-model="editedItem.idSorteo"
            :items="sorteos" @change="onSelectedCategory" outlined dense hide-details></v-select>
        </v-col>

        <v-col cols="12" sm="5" md="5">
          <v-text-field v-model="restringidos" label="Restringidos" class="text-input-blue"
            @change="changeValueComponent" outlined readonly dense hide-details></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-checkbox v-model="formatoMas" color="success" label="Formato +" outlined dense hide-details></v-checkbox>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-text-field label="Repetir Jugada" v-model="idJugada" class="pa-0" outlined dense @input="changeText"
            hide-details>
            <v-icon slot="append-outer" @click="repetirJugada" v-if="iconVisible">
              mdi-file-find
            </v-icon>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field v-model="editedItem.nombre" label="Cliente - Opcional" @change="changeValueComponent"
            @input="changeValueComponent" outlined dense hide-details></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field inputmode="numeric" v-model="editedItem.numeros" label="Números" @change="changeValueComponent"
            ref="numerosFocus" @input="handleInput" @keypress="onlyNumber" outlined dense hide-details></v-text-field>
        </v-col>

        <v-col align="center" cols="12" sm="1" md="1">
          <v-btn color="primary" fab small dark @click="mostrarInformacionAdicional">
            <v-icon>mdi-sort-descending</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field inputmode="numeric" v-model="editedItem.monto" :label="apuestaTitle" step="any"
            class="right-input" @change="changeValueComponent" @keypress="onlyNumber" ref="apuestaFocus"
            @focus="$event.target.select()" outlined dense hide-details></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field v-model="editedItem.total" label="Total ₡" step="any" min="0.00" class="right-input"
            @change="changeValueComponent" outlined readonly dense hide-details></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field v-if="visibleReventado" v-model="editedItem.montoReventado" label="Apuesta Reventado ₡"
            step="any" min="0" class="right-input" @keypress="onlyNumber" @change="changeValueComponent"
            @input="changeValueComponent" @focus="$event.target.select()" outlined dense hide-details></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-checkbox v-model="editedItem.desorden" label="Desorden" v-if="visibleMonazos"
            @change="changeValueComponent">
          </v-checkbox>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn color="primary" hide-details @click="validarNumeros()">
            <v-icon left> mdi-checkbox-marked </v-icon>
            Validar
          </v-btn>
        </v-col>

        <!-- <input v-on:keyup.enter="onEnter" /> -->

        <v-col cols="12" sm="9" md="9">
          <v-data-table id="mytable" hide-default-footer :headers="headers" :items="numeros" :items-per-page="-1"
            class="elevation-1">
            <template v-slot:top> </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="red" class="mr-2" @click="deleteItem(item)">
                mdi-delete-circle
              </v-icon>
            </template></v-data-table>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-btn color="primary" @click="preSave()" v-if="visibleGenerar">
            <v-icon left> mdi-file-send </v-icon>
            Generar
          </v-btn>
        </v-col>

        <v-dialog persistent transition="dialog-bottom-transition" v-model="dialogReport" max-width="380">
          <div v-if="showNewData">
            <Tiquete v-bind:allwedOnly="true" v-bind:tiqueteInfo="tiqueteInfo" @clicked="getTiqueteJugadaDesdeReporte"
              @closed="closed" />
          </div>
        </v-dialog>

        <v-dialog v-model="dialogInformacionAdicional" max-width="400px">
          <v-card rounded="lg">
            <v-card-title class="d-flex justify-space-between align-center">
              <div class="text-h5 text-medium-emphasis ps-2">
                Agregar Números
              </div>
              <v-btn size="small" text="Settings" icon="mdi-close" @click="closed"></v-btn>
            </v-card-title>

            <v-card-text>
              <v-divider class="mb-4"></v-divider>
              <div class="mb-4">Parejitas</div>
              <v-container>
                <v-row>
                  <v-col align="center" cols="12" sm="2" md="2">
                    <v-btn color="primary" dark @click="GetParejitas" :disabled="incluirValid">
                      Generar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text>
              <v-divider class="mb-4"></v-divider>
              <div class="mb-4">Rango</div>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-model="desde" label="Desde" class="pa-0" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-model="hasta" label="Hasta" class="pa-0" outlined dense></v-text-field>
                  </v-col>

                  <v-col align="center" cols="12" sm="2" md="2">
                    <v-btn color="primary" dark @click="GetRango">
                      Generar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text>
              <v-divider class="mb-4"></v-divider>
              <div class="mb-4">Terminación</div>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-model="numeroModo" label="Número" class="pa-0" outlined dense></v-text-field>
                  </v-col>

                  <v-col col="12" md="4" sm="4">
                    <v-select label="Modo" v-model="modoComboBox" :items="items" outlined dense hide-details></v-select>
                  </v-col>

                  <v-col align="center" cols="12" sm="2" md="2">
                    <v-btn color="primary" dark @click="GetNumerosModo" :disabled="incluirValid">
                      Generar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" class="mb-2" @click="closed">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
    </v-container>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Tiquete from "./Tiquete.vue";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
    Tiquete,
  },
  dark: true,
  data() {
    return {
      headersNormal: [
        {
          text: "Numero",
          align: "start",
          value: "numero",
          class: "blue lighten-1",
        },
        {
          text: "Monto",
          align: "start",
          value: "monto",
          class: "blue lighten-1",
        },
        { text: "Eliminar", value: "actions", width: 50, sortable: false, class: "blue lighten-1" },
      ],
      headersReventados: [
        {
          text: "Numero",
          align: "start",
          value: "numero",
          class: "blue lighten-1",
        },
        {
          text: "Monto",
          align: "start",
          value: "monto",
          class: "blue lighten-1",
        },
        {
          text: "Monto Reventado",
          align: "start",
          value: "montoReventado",
          class: "blue lighten-1",
        },
        { text: "Eliminar", value: "actions", width: 50, sortable: false, class: "blue lighten-1" },
      ],
      headers: [],
      visibleReventado: false,
      visibleGenerar: false,
      visibleMonazos: false,
      dialog: false,
      formatoMas: false,
      snackText: "",
      snackValue: false,
      dialogReport: false,
      showNewData: false,
      iconVisible: false,
      isRepetirJugada: false,
      dialogInformacionAdicional: false,
      timer: null,
      editedIndex: -1,
      sorteos: [],
      numeros: [],
      tiqueteReport: [],
      items: ['Inicial', 'Termina'],
      idJugada: "",
      oldSorteoId: 0,
      desde: 0,
      hasta: 0,
      numeroModo: 0,
      modoComboBox: "Inicial",
      rango: "",
      modo: "",
      parejitas: "",
      //respuestaNumeros: [],
      tiqueteInfo: {
        idTiquete: "",
        tipoTiquete: "",
      },
      respuestaNumeros: {
        valido: false,
        error: "",
        total: 0,
        //respuesta: [],
        numerosSorteos: [],
      },
      detalleditedIndex: -1,
      restringidos: "",
      numValidos: "",
      defaultItem: {
        id: 0,
        idSorteo: 0,
        monto: 0,
        numeros: "",
        cancelado: false,
        idUsuario: "",
        Fecha: new Date().toISOString().slice(0, 10),
        numerosSorteos: [],
        companyId: "",
        montoReventado: 0,
        desorden: false,
        nombre: "",
      },
      editedItem: {
        id: 0,
        idSorteo: 0,
        monto: 0,
        numeros: "",
        cancelado: false,
        idUsuario: "",
        Fecha: new Date().toISOString().slice(0, 10),
        numerosSorteos: [],
        companyId: "",
        montoReventado: 0,
        desorden: false,
        nombre: "",
      },
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.focusInput();
    });
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
    formTitle() {
      return this.strCia === '303650094' ? "Cashback" : "DASHBOARD TIEMPOS";
    },
    apuestaTitle() {
      return this.strCia === '303650094' ? "Monto Cashback" : "Apuesta";
    },
    sorteoTitle() {
      return this.strCia === '303650094' ? "Cashback" : "Sorteos";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.editedItem = Object.assign({}, this.editedItem);
    this.getSorteosFirst();
    //this.editedItem.idSorteo = this.sorteos[0]["id"];

    // window.addEventListener('keydown', (e) => {
    //   if (e.key == 'Enter') {
    //     console.log("se presionó enter");
    //     this.validarNumeros();
    //   }
    // });

  },

  methods: {

    // onEnter() {
    //   console.log("pressed enter");
    // },

    getSorteos() {
      //this.changeValueComponent();
      let me = this;
      axios
        .get("api/Tiempos/GetSorteos?companyId=" + this.strCia)
        .then(function (response) {
          me.sorteos = response.data;
          //me.editedItem.idSorteo = me.sorteos[0]["id"];
          me.getRestringidos();
          //me.onSelectedCategory(me.sorteos[0]["id"]);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    filterNumeric() {
      // Remove all non-numeric characters from the input
      this.number = this.number.replace(/[^0-9]/g, '');
    },

    onlyNumber($event) {
      console.log(this.formatoMAS); //keyCodes value
      //console.log("sabe que presionó +");
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      if (keyCode == 13) {
        console.log("se presionó enter");
        this.validarNumeros();
      }

      // Verificar si `formatoMAS` está habilitado
      // if (this.formatoMAS === true && keyCode === 43) {
      //   console.log("sabe que presionó +");
      //   return; // Permitir el carácter `+`
      // }

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) { // 46 is dot, 43 is plus
        $event.preventDefault();
      }

      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
      //   $event.preventDefault();
      // }
    },

    onlyVlidNumbers($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },

    onClickOutside() {
      this.dialogReport = false;
    },

    GetRango() {
      //this.changeValueComponent();
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);

      if (this.hasta < 100) {

        let me = this;
        axios
          .get("api/Tiempos/GetRango?start=" + this.desde +
            "&end=" +
            this.hasta)
          .then(function (response) {
            if (me.editedItem.monto < 1) {
              me.editedItem.monto = "";
              me.snackText = "Debe ingresar la apuesta";
              me.snackValue = true;
            } else {
              if (result[0].categoria === 1) {
                me.rango = response.data;
                me.dialogInformacionAdicional = false;
                me.editedItem.numeros = me.rango;
                me.validarNumeros();
                me.rango = "";
                me.modo = "";
                me.parejitas = "";
              }

              if (result[0].categoria === 2) {
                if (me.editedItem.montoReventado > 0) {
                  me.rango = response.data;
                  me.dialogInformacionAdicional = false;
                  me.editedItem.numeros = me.rango;
                  me.validarNumeros();
                  me.rango = "";
                  me.modo = "";
                  me.parejitas = "";
                } else {
                  me.snackText = "Debe ingresar la apuesta reventado";
                  me.snackValue = true;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });

      } else {
        this.snackText = "El número Hasta no puede ser mayor de 99";
        this.snackValue = true;
      }
    },

    GetNumerosModo() {
      if (this.modoComboBox === "Inicial") {
        this.GetInicial();
      }

      if (this.modoComboBox === "Termina") {
        this.GetTerminacion();
      }
    },

    GetTerminacion() {
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);

      //this.changeValueComponent();
      let me = this;
      axios
        .get("api/Tiempos/GetTerminacion?number=" + this.numeroModo)
        .then(function (response) {
          if (me.editedItem.monto < 1) {
            me.editedItem.monto = "";
            me.snackText = "Debe ingresar la apuesta";
            me.snackValue = true;
          } else {

            if (result[0].categoria === 1) {
              me.modo = response.data;
              me.dialogInformacionAdicional = false;
              me.editedItem.numeros = me.modo;
              me.validarNumeros();
              me.rango = "";
              me.modo = "";
              me.parejitas = "";
            }
            if (result[0].categoria === 2) {
              if (me.editedItem.montoReventado > 0) {
                me.modo = response.data;
                me.dialogInformacionAdicional = false;
                me.editedItem.numeros = me.modo;
                me.validarNumeros();
                me.rango = "";
                me.modo = "";
                me.parejitas = "";
              } else {
                me.snackText = "Debe ingresar la apuesta reventado";
                me.snackValue = true;
              }
            }

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    GetInicial() {
      //this.changeValueComponent();
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);

      let me = this;
      axios
        .get("api/Tiempos/GetInicial?number=" + this.numeroModo)
        .then(function (response) {
          if (me.editedItem.monto < 1) {
            me.editedItem.monto = "";
            me.snackText = "Debe ingresar la apuesta";
            me.snackValue = true;
          } else {

            if (result[0].categoria === 1) {
              me.modo = response.data;
              me.dialogInformacionAdicional = false;
              me.editedItem.numeros = me.modo;
              me.validarNumeros();
              me.rango = "";
              me.modo = "";
              me.parejitas = "";
            }

            if (result[0].categoria === 2) {
              if (me.editedItem.montoReventado > 0) {
                me.modo = response.data;
                me.dialogInformacionAdicional = false;
                me.editedItem.numeros = me.modo;
                me.validarNumeros();
                me.rango = "";
                me.modo = "";
                me.parejitas = "";
              } else {
                me.snackText = "Debe ingresar la apuesta reventado";
                me.snackValue = true;
              }
            }

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    GetParejitas() {
      //this.changeValueComponent();
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);

      let me = this;
      axios
        .get("api/Tiempos/GetParejitas")
        .then(function (response) {
          if (me.editedItem.monto < 1) {
            me.editedItem.monto = "";
            me.snackText = "Debe ingresar la apuesta";
            me.snackValue = true;
          } else {

            if (result[0].categoria === 1) {
              me.parejitas = response.data;
              me.dialogInformacionAdicional = false;
              me.editedItem.numeros = me.parejitas;
              me.validarNumeros();
              me.rango = "";
              me.modo = "";
              me.parejitas = "";
            }

            if (result[0].categoria === 2) {

              if (me.editedItem.montoReventado > 0) {
                me.parejitas = response.data;
                me.dialogInformacionAdicional = false;
                me.editedItem.numeros = me.parejitas;
                me.validarNumeros();
                me.rango = "";
                me.modo = "";
                me.parejitas = "";
              } else {
                me.snackText = "Debe ingresar la apuesta reventado";
                me.snackValue = true;
              }
            }

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    mostrarInformacionAdicional() {
      this.dialogInformacionAdicional = true;
      this.desde = 0;
      this.hasta = 0;
      this.numeroModo = 0;
    },

    focusInput() {
      this.$refs.numerosFocus.focus();
      //this.closed();
    },

    focusApuestaInput() {
      this.$refs.apuestaFocus.focus();
      //this.$refs.apuestaFocus.select();
    },

    handleInput() {
      clearTimeout(this.timer);

      //this.editedItem.numeros = this.editedItem.numeros.replace(/[^0-9]/g, '');

      this.timer = setTimeout(() => {
        //alert('searching...');
        //this.focusApuestaInput();
        if (this.editedItem.monto < 1) {
          this.editedItem.monto = "";
        }

        if (this.editedItem.numeros > 99) {
          this.editedItem.numeros = ""; // Minimum value
          this.snackText = "Ingrese un número valido del 00 al 99";
          this.snackValue = true;
        } else {
          console.log(this.formatoMas);
          if (!this.formatoMas) {
            this.focusApuestaInput();
          }else{
            this.editedItem.numeros = this.editedItem.numeros + "+";
          }
        }
        console.log('Text changed:', this.editedItem.numeros);
      }, 700);
    },

    getSorteosFirst() {
      //this.changeValueComponent();
      let me = this;
      axios
        .get("api/Tiempos/GetSorteos?companyId=" + this.strCia)
        .then(function (response) {
          me.sorteos = response.data;
          me.editedItem.idSorteo = me.sorteos[0]["id"];
          me.getRestringidos();
          me.onSelectedCategory(me.sorteos[0]["id"]);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRestringidos() {
      //this.changeValueComponent();
      let me = this;
      axios
        .get(
          "api/Tiempos/GetTiquetesRestringidos?idSorteo=" +
          this.editedItem.idSorteo +
          "&companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.restringidos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRestringidosReventados() {
      //this.changeValueComponent();
      let me = this;
      axios
        .get(
          "api/Tiempos/GetTiquetesReventadosRestringidos?idSorteo=" +
          this.editedItem.idSorteo +
          "&companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.restringidos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRestringidosMonazos() {
      //this.changeValueComponent();
      let me = this;
      axios
        .get(
          "api/Tiempos/GetTiquetesMonazosRestringidos?idSorteo=" +
          this.editedItem.idSorteo +
          "&companyId=" +
          this.strCia,
        )
        .then(function (response) {
          me.restringidos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    validarNumeros() {
      //this.closed();
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);
      if (result[0].categoria === 1) {
        this.validarNumerosNormal();
      }

      if (result[0].categoria === 2) {
        this.validarNumerosReventados();
      }

      if (result[0].categoria === 3) {
        this.validarNumerosMonazos();
      }
    },

    validarNumerosNormal() {
      this.changeValueComponent();
      this.editedItem.idUsuario = this.user.cedula;
      this.editedItem.numerosSorteos = this.numeros;
      this.editedItem.companyId = this.strCia;
      let me = this;
      //if (this.editedIndex > -1) {
      axios
        .post("api/Tiempos/ValidarNumeros", this.editedItem)
        .then(function (response) {
          me.respuestaNumeros = response.data;
          //me.respuestaNumeros = response.data;
          me.focusInput();
          if (!me.respuestaNumeros.valido) {
            me.snackText = me.respuestaNumeros.error;
            me.snackValue = true;
          } else {
            me.numeros = me.respuestaNumeros.numerosSorteos;
            me.editedItem.total = me.respuestaNumeros.total;
            me.editedItem.numeros = "";
            me.visibleGenerar = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          me.snackText = "Ha ocurrido un error inesperado";
          me.snackValue = true;
        });
      //}
    },

    validarNumerosReventados() {
      this.changeValueComponent();
      this.editedItem.idUsuario = this.user.cedula;
      this.editedItem.numerosSorteos = this.numeros;
      this.editedItem.companyId = this.strCia;
      let me = this;
      axios
        .post("api/Tiempos/ValidarNumerosReventados", this.editedItem)
        .then(function (response) {
          me.respuestaNumeros = response.data;
          me.focusInput();
          if (!me.respuestaNumeros.valido) {
            me.snackText = me.respuestaNumeros.error;
            me.snackValue = true;
          } else {
            me.numeros = me.respuestaNumeros.numerosSorteos;
            me.editedItem.total = me.respuestaNumeros.total;
            me.editedItem.numeros = "";
            me.visibleGenerar = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          me.snackText = "Ha ocurrido un error inesperado";
          me.snackValue = true;
        });
    },

    validarNumerosMonazos() {
      this.changeValueComponent();
      this.editedItem.idUsuario = this.user.cedula;
      this.editedItem.numerosSorteos = this.numeros;
      this.editedItem.companyId = this.strCia;
      let me = this;
      axios
        .post("api/Tiempos/ValidarNumerosMonazos", this.editedItem)
        .then(function (response) {
          me.respuestaNumeros = response.data;
          if (!me.respuestaNumeros.valido) {
            me.snackText = me.respuestaNumeros.error;
            me.snackValue = true;
          } else {
            me.numeros = me.respuestaNumeros.numerosSorteos;
            me.editedItem.total = me.respuestaNumeros.total;
            me.editedItem.numeros = "";
            me.visibleGenerar = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          me.snackText = "Ha ocurrido un error inesperado";
          me.snackValue = true;
        });
    },

    changeText() {
      this.iconVisible = true;
    },

    getTiqueteReport() {
      this.dialogReport = true;
      this.showNewData = true;
    },

    repetirJugada() {
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);
      if (result[0].categoria === 1) {
        this.getTiqueteJugada();
      }

      if (result[0].categoria === 2) {
        this.getJugadaReventadoDesdeReporte();
      }

      if (result[0].categoria === 3) {
        this.validarNumerosMonazos();
      }

    },

    getTiqueteJugada() {
      this.snackValue = false;
      let me = this;
      axios
        .get("api/Tiempos/GetTiqueteReport?idTiquete=" + this.idJugada +
          "&companyId=" +
          this.strCia +
          "&reimpresion=" +
          this.tiqueteInfo.reimpresion)
        .then(function (response) {
          me.tiqueteReport = response.data;
          //me.editedItem.numeros = me.tiqueteReport.numDetalles;
          me.editedItem.monto = me.tiqueteReport.monto;
          me.editedItem.nombre = me.tiqueteReport.nombre;
          me.editedItem.total = me.tiqueteReport.total;
          //me.editedItem.numerosSorteos = me.tiqueteReport.numerosSorteos;
          me.editedItem.montoReventado = 0;
          me.numeros = me.tiqueteReport.numerosSorteos;
          me.visibleGenerar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getJugadaReventadoDesdeReporte() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialogReport = false;
      this.isRepetirJugada = true;
      let me = this;
      axios
        .get("api/Tiempos/GetTiqueteReventadoReport?idTiquete=" + this.tiqueteInfo.idTiquete +
          "&companyId=" +
          this.strCia +
          "&reimpresion=" +
          this.tiqueteInfo.reimpresion)
        .then(function (response) {
          me.tiqueteReport = response.data;
          //me.editedItem.numeros = me.tiqueteReport.numDetalles;
          me.editedItem.monto = me.tiqueteReport.monto;
          me.editedItem.montoReventado = me.tiqueteReport.montoReventado;
          me.editedItem.nombre = me.tiqueteReport.nombre;
          me.editedItem.total = me.tiqueteReport.total;
          //me.editedItem.numerosSorteos = me.tiqueteReport.numerosSorteos;
          me.numeros = me.tiqueteReport.numerosSorteos;
          me.visibleGenerar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiqueteJugadaDesdeReporte() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialogReport = false;
      this.isRepetirJugada = true;
      let me = this;
      axios
        .get("api/Tiempos/GetTiquete" + this.tiqueteInfo.tipoTiquete + "Report?idTiquete=" + this.tiqueteInfo.idTiquete +
          "&companyId=" +
          this.strCia +
          "&reimpresion=" +
          this.tiqueteInfo.reimpresion)
        .then(function (response) {
          me.tiqueteReport = response.data;
          //me.editedItem.numeros = me.tiqueteReport.numDetalles;
          me.editedItem.monto = me.tiqueteReport.monto;
          me.editedItem.montoReventado = me.tiqueteReport.montoReventado;
          me.editedItem.nombre = me.tiqueteReport.nombre;
          me.editedItem.total = me.tiqueteReport.total;
          //me.editedItem.numerosSorteos = me.tiqueteReport.numerosSorteos;
          me.numeros = me.tiqueteReport.numerosSorteos;
          me.visibleGenerar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeValueComponent() {
      this.snackValue = false;
    },

    onSelectedCategory(id) {
      const result = this.sorteos.filter(c => c.id == id);

      if (result[0].categoria === 1) {
        this.restringidos = "";
        this.getRestringidos();
        this.visibleReventado = false;
        this.visibleMonazos = false;
        this.headers = this.headersNormal;
      }

      if (result[0].categoria === 2) {
        this.restringidos = "";
        this.getRestringidosReventados();
        this.visibleReventado = true;
        this.visibleMonazos = false;
        this.headers = this.headersReventados;
      }

      if (result[0].categoria === 3) {
        this.restringidos = "";
        this.getRestringidosMonazos();
        this.visibleReventado = false;
        this.visibleMonazos = true;
        this.headers = this.headersNormal;
      }

      if (!this.isRepetirJugada) {
        this.editedItem.numeros = "";
        this.editedItem.monto = 0;
        this.editedItem.total = 0;
        this.editedItem.montoReventado = 0;
        this.idJugada = "";
        this.numeros = [];
        this.editedItem.nombre = "";
      } else {
        //this.editedItem.numeros = "";
        //this.editedItem.monto = 0;
        //this.editedItem.total = 0;
        //this.editedItem.montoReventado = 0;
        this.idJugada = "";
        //this.numeros = [];
        //this.editedItem.nombre = "";
      }
      //console.log(result[0].categoria)
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialogReport = false;
      this.dialogInformacionAdicional = false;
    },

    deleteItem(item) {
      this.detalleEditedIndex = this.numeros.indexOf(item);
      console.log(this.detalleEditedIndex);
      if (confirm("¿Está seguro de que desea eliminar número de la lista?")) {
        this.numeros.splice(this.detalleEditedIndex, 1);
        this.editedItem.numerosSorteos = this.numeros;
        this.validarNumeros();
      }
    },

    preSave() {
      this.visibleGenerar = false;
      const result = this.sorteos.filter(c => c.id == this.editedItem.idSorteo);
      if (result[0].categoria === 1) {
        this.save();
      }

      if (result[0].categoria === 2) {
        this.saveReventados();
      }

      if (result[0].categoria === 3) {
        this.saveMonazos();
      }
    },

    save() {
      //this.snackValue = false;
      this.editedItem.idUsuario = this.user.cedula;
      this.editedItem.numerosSorteos = this.numeros;
      this.editedItem.companyId = this.strCia;
      //var oldSorteoId = this.editedItem.idSorteo;
      let me = this;

      if (this.numeros.length < 1) {
        me.snackText = "Por favor seleccione los números a vender";
        me.snackValue = true;
      } else {
        axios
          .post("api/Tiempos/InsertTiquetes", this.editedItem)
          .then(function (response) {
            me.tiqueteInfo.idTiquete = response.data;
            me.tiqueteInfo.tipoTiquete = "";
            if (me.tiqueteInfo.idTiquete > 0) {
              me.snackText =
                "Se ha registrado el tiquete #" +
                response.data +
                " correctamente";
              me.snackValue = true;
              me.editedItem.numeros = "";
              me.editedItem.nombre = "";
              me.editedItem.monto = 0;
              me.editedItem.total = 0;
              me.editedItem.montoReventado = 0;
              me.idJugada = "";
              me.numeros = [];
              me.restringidos = "";
              me.isRepetirJugada = false;
              me.getTiqueteReport();
              me.getSorteos();
              me.focusInput();
            } else {
              me.snackText =
                "No se ha podido crear el tiquete al parecer el monto ya no está disponible por favor valide";
              me.snackValue = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      //console.log(oldSorteoId);
      //this.editedItem.idSorteo = oldSorteoId;
    },
    saveReventados() {
      //this.snackValue = false;
      this.editedItem.idUsuario = this.user.cedula;
      this.editedItem.numerosSorteos = this.numeros;
      this.editedItem.companyId = this.strCia;
      let me = this;

      if (this.numeros.length < 1) {
        me.snackText = "Por favor seleccione los números a vender";
        me.snackValue = true;
      } else {
        axios
          .post("api/Tiempos/InsertTiquetesReventado", this.editedItem)
          .then(function (response) {
            me.tiqueteInfo.idTiquete = response.data;
            me.tiqueteInfo.tipoTiquete = "Reventado";
            me.snackText =
              "Se ha registrado el tiquete #" +
              response.data +
              " correctamente";
            me.snackValue = true;
            me.editedItem.numeros = "";
            me.editedItem.nombre = "";
            me.editedItem.monto = 0;
            me.editedItem.montoReventado = 0;
            me.editedItem.total = 0;
            me.idJugada = "";
            me.numeros = [];
            me.restringidos = "";
            me.isRepetirJugada = false;
            me.getTiqueteReport();
            me.getSorteos();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    saveMonazos() {
      //this.snackValue = false;
      this.editedItem.idUsuario = this.user.cedula;
      this.editedItem.numerosSorteos = this.numeros;
      this.editedItem.companyId = this.strCia;
      let me = this;

      if (this.numeros.length < 1) {
        me.snackText = "Por favor seleccione los números a vender";
        me.snackValue = true;
      } else {
        axios
          .post("api/Tiempos/InsertTiquetesMonazos", this.editedItem)
          .then(function (response) {
            me.tiqueteInfo.idTiquete = response.data;
            me.tiqueteInfo.tipoTiquete = "Monazo";
            me.snackText =
              "Se ha registrado el tiquete #" +
              response.data +
              " correctamente";
            me.snackValue = true;
            me.editedItem.numeros = "";
            me.editedItem.nombre = "";
            me.editedItem.monto = 0;
            me.editedItem.total = 0;
            me.editedItem.montoReventado = 0;
            me.idJugada = "";
            me.numeros = [];
            me.restringidos = "";
            me.isRepetirJugada = false;
            me.getTiqueteReport();
            me.getSorteos();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.text-input-blue .v-text-field__slot input {
  color: red !important;
}
</style>